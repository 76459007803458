import { cx } from '@emotion/css';
import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';
import { spsHeaderCss } from './styles/Header/spsHeader.styles';

export const config: SiteConfiguration = {
  domainName: 'snappartnersummit.com',
  theme: {
    breakTemplates: [], // No breaks
  },

  globalNavProps: {
    showGlobalLinks: true,
    className: cx('sps-header', spsHeaderCss),
  },

  headerProps: {
    className: cx(spsHeaderCss),
  },

  siteMap: {
    customPaths: {
      // Top level domain, should include cookie-settings in site map
      '/cookie-settings': { isLocalized: true },
    },
  },

  csp: {
    frameSrc: ['https://insight.adsrvr.org/'],
    mediaSrc: [
      // Akamai integration. There are dozens of subdomains we'd need to support. Every stream
      // gets their own. This has been more of a liability trying to keep track of all of them.
      '*.akamaized.net',
    ],
    styleSrc: [
      // Akamai integration
      'https://amp.akamaized.net',
    ],
    imgSrc: [
      // bitmoji reactions
      'https://sdk.bitmoji.com',
      // Trade Desk GTM Tag
      'https://insight.adsrvr.org',
    ],
    scriptSrc: [
      // Akamai integration
      'https://amp.akamaized.net',
      // Trade Desk GTM Tag
      'https://js.adsrvr.org',
      "'sha256-4WkCuzWCERad6mpAEaHhmDb0v3Fn8XUxhOd8MySpdQw='",
      // 'Meta Pixel ID 2109733575881852' GTM Tag
      "'sha256-SsqQk5SechSdHwfN1LFLqAUbhHuo9EvyRFFF1mXHhTs='",
    ],
    connectSrc: [
      // Akamai integration. See comment in mediaSrc.
      'https://*.akamaized.net',
      'https://*.akamaihd.net',
      'http://amd.akamaized.net', // For akamai fonts (not available via https)
      'http://amp.akamaized.net', // For akamai images (not available via https)
      // Akamai integration. Enable player to render videos hosted on Contentful
      'https://videos.ctfassets.net',
      // Bitmovin video analytics integration
      'https://analytics-ingress-global.bitmovin.com',
    ],
    fontSrc: [
      // Akamai integration
      'https://amp.akamaized.net',
    ],
  },

  trackingSettings: {
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.SPS },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'UA-41740027-93',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'sps-com',
        dsn: 'https://141d1a6919f44284af1162ba53dff185@sentry.sc-prod.net/177',
      },
    ],
  },
};
