import { css } from '@emotion/css';

export const volumeControlCss = css`
  width: 70px;
  /* -webkit prefix needed by Safari until 2021. */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  transform: rotate(-90deg);
  position: absolute;
  right: -19px;
  bottom: 73px;
  z-index: 3;
  opacity: 0;

  &::-webkit-slider-runnable-track {
    background: white;
    height: 2px;
    border-radius: 4px;
  }

  &::-moz-range-track {
    background: grey;
    height: 2px;
    border-radius: 4px;
  }

  &::-moz-range-progress {
    background-color: white;
  }

  &[type='range']::-webkit-slider-thumb {
    /* Override default look */
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    appearance: none;
    margin-top: -9px; /* Manually tuned */
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }

  &[type='range']::-moz-range-thumb {
    border: none; /*Removes extra border that FF applies*/
    background-color: white;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }

  &[type='range']:focus {
    outline: none;
  }

  &[type='range']:focus::-webkit-slider-thumb {
    border: 1px solid white;
    outline: 3px solid white;
    outline-offset: 0.125rem;
  }

  &[type='range']:focus::-moz-range-thumb {
    border: 1px solid white;
    outline: 3px solid white;
    outline-offset: 0.125rem;
  }
`;

export const volumeIconCss = css`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  border-radius: 50%;
`;
