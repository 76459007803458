import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@snapchat/mw-common';
import type { FormBody, MultiSelectOption } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../../../../../AppContext';
import type { Sps2023SiteData } from '../types';
import { Sps2023RegistrationStatus } from '../types';
import { RegistrationForm } from './RegistrationForm';
import { RegistrationResult } from './RegistrationResult';
import type { Sps2023RegistrationProps } from './Sps2023RegistrationQuery';

enum FormState {
  ERROR = 'Error',
  FAILED = 'Failed',
  INITIALIZING = 'Initializing',
  REGISTERED = 'Registered',
  UNREGISTERED = 'Unregistered',
  WELCOME_BACK = 'WelcomeBack',
}

export const firstNameStorageKey = 'sps-2023-firstName';
export const registrationStatusStorageKey = 'sps-2023-registrationStatus';
export const interestsStorageKey = 'sps-2023-interests';

const defaultAnimationLines = ['SNAP', 'PARTNER', 'SUMMIT', '2023'];

export const Sps2023Registration: FC<Sps2023RegistrationProps> = ({
  blurb,
  formTitle,
  formErrorText,
  registrationForm,
  irlConfirmation,
  virtualConfirmation,
  notAttendingConfirmation,
  errorPage,
  defaultAnimationTextLines,
  personalizedAnimationTextLines,
  noNameAnimationTextLines,
  requireEmailToken,
  isClosed,
  closedText,
}) => {
  const siteData = useContext(AppContext).siteData as Sps2023SiteData;

  // ==========================================================================
  // State
  // ==========================================================================

  const [registrationStatus, setRegistrationStatus] = useState<
    Sps2023RegistrationStatus | undefined
  >();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [formState, setFormState] = useState<FormState>(FormState.INITIALIZING);

  // Initialize state. Must be done in useEffect since localStorage is not available on SSR
  useEffect(
    () => {
      // Determine the status of a user in one of two ways depending
      // on if the user is IRL or not, and we know a user is IRL if the siteData object has
      // a populated decodedEmail attribute...
      //
      // For IRL users, because we can identify them from their decoded email, we should get
      // their status from Salesforce. siteData.status is populated from a Salesforce query during
      // the server side render, so we can just use that value.
      //
      // For virtual users, our best guess is to check local storage for a previously submitted
      // status on this device.
      const initialRegistrationStatus = siteData.decodedEmail
        ? siteData?.status
        : (getLocalStorageItem(registrationStatusStorageKey) as Sps2023RegistrationStatus | null) ??
          undefined;

      // Prefer to set get first name from salesforce (i.e. siteData); however, if unavailable
      // (as is the case for virtual flow) then fallback to local storage.
      const initialFirstNameState =
        siteData?.firstName ??
        (getLocalStorageItem(firstNameStorageKey) as Sps2023RegistrationStatus | null) ??
        undefined;

      // Determine initial form state
      let initialFormState = FormState.INITIALIZING;

      if (
        initialRegistrationStatus &&
        (!requireEmailToken || initialRegistrationStatus !== Sps2023RegistrationStatus.Virtual)
      ) {
        // Found user in salesforce OR local storage so show the welcome back screen.
        // Exception: Users who have already registered virtually are allowed to register
        // IRL (given the have a magic link), so for those users, we do NOT show the
        // Welcome Back screen.
        initialFormState = FormState.WELCOME_BACK;
      } else if (requireEmailToken && !siteData.decodedEmail) {
        // Invalid or absent token in URL
        initialFormState = FormState.ERROR;
      } else {
        // User is unregistered
        initialFormState = FormState.UNREGISTERED;
      }

      setRegistrationStatus(initialRegistrationStatus);
      setFirstName(initialFirstNameState);
      setFormState(initialFormState);
    },
    // Disable exhaustive deps here in order to pass an empty array and therefore ensure that
    // this useEffect only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // ==========================================================================
  // Helper Functions
  // ==========================================================================

  const scrollToTop = () => scroll(0, 0);

  // ==========================================================================
  // Callbacks
  // ==========================================================================

  const onFormSubmit = (response: Response, formBody: FormBody) => {
    if (response.ok) {
      // Persist name and status in local storage for confirmation ui
      // TODO: fix this in subsequent ticket: ENTWEB-7221
      const firstNameField = (formBody['first_name'] ?? '') as string;
      setLocalStorageItem(firstNameStorageKey, firstNameField);
      // TODO: fix this in subsequent ticket: ENTWEB-7221
      const registrationStatus = (formBody['registration_status'] ?? 'Virtual') as string;
      setLocalStorageItem(registrationStatusStorageKey, registrationStatus);

      if (formBody['interests']) {
        const selectedInterests = (formBody['interests'] as MultiSelectOption[]).reduce(
          (prev, curr, idx) => {
            return idx === 0 ? curr.name : `${prev},${curr.name}`;
          },
          ''
        );
        setLocalStorageItem(interestsStorageKey, selectedInterests);
      }

      setFirstName(firstNameField);
      setRegistrationStatus(registrationStatus as Sps2023RegistrationStatus);
      setFormState(FormState.REGISTERED);
    } else {
      setFormState(FormState.FAILED);
    }

    scrollToTop();
  };

  const onStartOver = () => {
    removeLocalStorageItem(registrationStatusStorageKey);
    removeLocalStorageItem(firstNameStorageKey);
    setFormState(FormState.UNREGISTERED);
    scrollToTop();
  };

  // ==========================================================================
  // Getters
  // ==========================================================================

  const getPersonalizedAnimationText = () => {
    // Prefer to get name from site data, which will be populated if the user accessed
    // this page with a registration token
    const name = firstName ?? getLocalStorageItem(firstNameStorageKey);
    if (!name || name.length > 8) return noNameAnimationTextLines;
    return personalizedAnimationTextLines?.map(line =>
      line.replace('{firstName}', name.toLocaleUpperCase())
    );
  };

  const getFormContent = (showError = false) => {
    return (
      <RegistrationForm
        blurb={blurb}
        title={formTitle}
        animationTextLines={defaultAnimationTextLines ?? []}
        form={registrationForm}
        spsRegistrationEmail={siteData.decodedEmail}
        onFormSubmit={onFormSubmit}
        error={showError ? formErrorText : undefined}
        isClosed={isClosed}
        closedText={closedText}
      />
    );
  };

  const getConfirmationContent = (isReturningUser = false) => {
    let animationTextLines = defaultAnimationTextLines;

    if (!isReturningUser && registrationStatus !== Sps2023RegistrationStatus.InvitedNo) {
      animationTextLines = getPersonalizedAnimationText();
    }

    if (registrationStatus === Sps2023RegistrationStatus.InvitedNo) {
      return (
        <RegistrationResult
          animationTextLines={animationTextLines ?? []}
          isReturningUser={false} // Hardcode false for "No" state since title should come from Contentful regardless
          firstName={firstName}
          registrationStatus={registrationStatus}
          {...notAttendingConfirmation}
        />
      );
    }

    if (
      registrationStatus === Sps2023RegistrationStatus.InvitedVirtual ||
      registrationStatus === Sps2023RegistrationStatus.Virtual
    ) {
      return (
        <RegistrationResult
          animationTextLines={animationTextLines ?? []}
          isReturningUser={isReturningUser}
          firstName={firstName}
          registrationStatus={registrationStatus}
          onStartOver={
            registrationStatus === Sps2023RegistrationStatus.Virtual && !requireEmailToken
              ? onStartOver
              : undefined
          }
          {...virtualConfirmation}
        />
      );
    }

    // We know registrationStatus === Sps2023RegistrationStatus.InvitedInPerson
    return (
      <RegistrationResult
        animationTextLines={animationTextLines ?? []}
        isReturningUser={isReturningUser}
        firstName={firstName}
        registrationStatus={registrationStatus}
        {...irlConfirmation}
      />
    );
  };

  const getErrorContent = () => {
    return <RegistrationResult animationTextLines={defaultAnimationLines} {...errorPage} />;
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <div>
      {formState === FormState.UNREGISTERED && getFormContent()}
      {formState === FormState.REGISTERED && getConfirmationContent()}
      {formState === FormState.WELCOME_BACK && getConfirmationContent(true)}
      {formState === FormState.FAILED && getFormContent(true)}
      {formState === FormState.ERROR && getErrorContent()}
      {/* and if formState === FormState.Initializing then simply render nothing */}
    </div>
  );
};
