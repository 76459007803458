import { css } from '@emotion/css';
import { Plain } from '@snapchat/snap-design-system-marketing';

export const bitmojiReactionToggleDarkModeCss = css`
  --button-flat-fg-color: ${Plain.WHITE};
`;

export const bitmojiReactionsCss = css`
  list-style-type: none;
  display: flex;
  margin-top: 10px;
  min-width: 300px;

  /* Add spacing between different controls */
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export const bitmojiReactionCss = css`
  background: none;
  transition: transform 0.2s ease-out;

  img {
    cursor: pointer;
    width: 50px;
    opacity: 0;
    transform: scale(0.5);
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.69, 1.56);
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(0.7);

    img {
      cursor: not-allowed;
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &:nth-child(2) img {
    transition-delay: 0.4s;
  }

  &:nth-child(3) img {
    transition-delay: 0.3s;
  }

  &:nth-child(4) img {
    transition-delay: 0.2s;
  }

  &:nth-child(5) img {
    transition-delay: 0.1s;
  }

  .show-bitmoji & {
    img {
      transform: scale(1);
      opacity: 1;
    }

    &:nth-child(2) img {
      transition-delay: 0s;
    }

    &:nth-child(3) img {
      transition-delay: 0.1s;
    }

    &:nth-child(4) img {
      transition-delay: 0.2s;
    }

    &:nth-child(5) img {
      transition-delay: 0.3s;
    }

    &:nth-child(6) img {
      transition-delay: 0.4s;
    }
  }
`;

export const bitmojiReactionsNoBitmojiTextCss = css`
  display: none;

  .show-bitmoji & {
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
`;
