import { gql } from '@apollo/client';

import { blockAllFragment } from '../../../../../components/Block';
import { formFragments } from '../../../../../components/Form';
import { contentfulSysIdFragment } from '../../../../../utils/contentful/contentfulQuery';

export const confirmationFragment = gql`
  fragment ConfirmationAll2024 on Sps2024Confirmation {
    title
    primaryBlurb {
      json
    }
    middleBlurb {
      json
    }
    secondaryBlurb {
      json
    }
    showSaveTheDate
    showStartOverButton
    buttonText
    belowTheFoldCollection(limit: 5) {
      items {
        ...ContentfulSysId
        ... on Block {
          ...BlockAll
        }
      }
    }
    usernameFormBlurb {
      json
    }
    usernameForm {
      ... on Form {
        ...FormAll
      }
    }
    ...ContentfulSysId
  }
  ${formFragments.all}
  ${blockAllFragment}
  ${contentfulSysIdFragment}
`;
