import { cx } from '@emotion/css';
import { Icon } from '@snapchat/snap-design-system-marketing';
import type { FC, PropsWithChildren } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  toastCloseBorderCss,
  toastCloseButtonCss,
  toastCloseIconCss,
  toastContainerCss,
  toastOpacityTransitionTimeMs,
} from './Toast.style';

export interface ToastProps extends PropsWithChildren {
  /** Sets whether the toast is open or closed */
  open: boolean;

  /**
   * This is run before the toast transitions out. This is usually the place to update the open
   * state to false.
   */
  onClose: () => void;

  /** There is a delay for the toast to transition out. This runs after the toast transitions out. */
  onCloseTransitionOut?: () => void;

  /** The maximum time for which the toast should appear on the page. */
  autoCloseTimeMs: number;

  className?: string;
}

/**
 * Component that shows a toast notification that disappears after a timeout.
 *
 * Sample usage:
 *
 *     const [showToast, setShowToast] = useState<boolean>(false);
 *
 *     <button onClick={() => setShowToast(true)}>Show plain toast</button>;
 *     {
 *       <Toast open={showToast} onClose={() => setShowToast(false)}></Toast>;
 *     }
 */
export const Toast: FC<ToastProps> = ({
  open,
  onClose,
  onCloseTransitionOut,
  autoCloseTimeMs,
  className,
  children,
}) => {
  const [portal, setPortal] = useState<HTMLElement | null>();
  const closeBorderRef = useRef<HTMLHRElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const openRef = useRef<boolean>(open);

  useEffect(() => {
    setPortal(document.body);
  }, []);

  /**
   * This effect makes the second border around the close button get smaller in size. I (Alex) tried
   * to do this w/ pure CSS and animations, but seems we can't animate inner values inside
   * conic-radius. And the @property definition that would allow us to animate the --degree variable
   * isn't supported in Safari/Firefor as of 2023. See: https://caniuse.com/?search=%40property
   */
  useEffect(() => {
    if (!open) return;

    const startTime = Date.now();
    let interval: ReturnType<typeof setInterval> | null;

    function updateCloseBorder() {
      const progress = (Date.now() - startTime) / autoCloseTimeMs;

      if (progress > 1) {
        interval && clearInterval(interval);
        interval = null;
        return;
      }
      const degree = progress * 360;
      closeBorderRef.current?.style.setProperty('--degree', `${degree.toFixed(0)}deg`);
    }

    interval = setInterval(updateCloseBorder, 50);

    return () => {
      interval && clearInterval(interval);
      interval = null;
    };
    // Add props.open as a dependency to re-trigger the animation if it changes to true
  }, [autoCloseTimeMs, closeBorderRef, open]);

  /** Function to trigger closing animation and onclose hook. */
  const internalOnClose = useCallback(() => {
    onClose();

    containerRef.current?.classList.remove('open');
    containerRef.current?.classList.add('closed');

    return setTimeout(() => {
      onCloseTransitionOut?.();
      containerRef.current?.classList.add('hidden');
    }, toastOpacityTransitionTimeMs);
  }, [onClose, onCloseTransitionOut]);

  /** This effect triggers the toast appearance/disappearance based on props.open */
  useEffect(() => {
    const prevOpenIsTrue = openRef.current;

    // Prev and current value are both closed, don't do anything
    if (!open && !prevOpenIsTrue) {
      return;
    }

    // Current value is closed and prev value is open. Trigger the onClose callback.
    if (!open) {
      const onCloseTimeout = internalOnClose();
      openRef.current = open;
      return () => clearTimeout(onCloseTimeout);
    }

    // Current value is open. Show the toast and trigger the autoclose timeout
    setTimeout(() => {
      // Adding the open classname with a delay so the element can be attached to the
      // DOM so that the classname triggers a transition animation (fade-in).
      containerRef.current?.classList.remove('hidden');
      containerRef.current?.classList.add('open');
      containerRef.current?.classList.remove('closed');
    }, 25);

    openRef.current = open;
    const autoCloseTimeout = setTimeout(internalOnClose, autoCloseTimeMs);
    return () => clearTimeout(autoCloseTimeout);
  }, [open, internalOnClose, autoCloseTimeMs]);

  if (!portal) {
    return null;
  }

  const toastContainer = (
    <aside ref={containerRef} data-testid="mwp-toast" className={cx(toastContainerCss, className)}>
      <hr ref={closeBorderRef} className={toastCloseBorderCss} />
      <button
        data-testid="mwp-toast-close"
        className={toastCloseButtonCss}
        onClick={() => internalOnClose()}
      >
        <Icon name="cross" className={toastCloseIconCss} />
      </button>
      {children}
    </aside>
  );

  return <>{createPortal(toastContainer, portal)}</>;
};
