import type { FC } from 'react';

import { Button } from '../../../../../components/Button/Button';
import type { ContentfulIdVariable } from '../../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../../hooks/useContentfulQuery';
import { renderRichText } from '../../../../../utils/renderText/renderRichText';
import { Sps2023Animation } from '../Sps2023Animation';
import { homeInfoCss, sps2023HomeBlockCss } from './Sps2023Home.style';
import type { SpsHome2023Props } from './Sps2023HomeQuery';
import { spsHome2023Query } from './Sps2023HomeQuery';

/**
 * Homepage block for SPS 2023. Mocks:
 * https://www.figma.com/file/ys7GLHs9WHil6glFshP5D5/SPS-2023?node-id=353%3A12156&t=D8kZQ9m9MD9do8Hx-0
 */
export const Sps2023Home: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<SpsHome2023Props, ContentfulIdVariable>(spsHome2023Query, {
    variables: { id: props.id },
  });

  if (!data) return null;

  const { logoLines, title, subtitle, callToAction, label, body } = data.spsHome2023;

  return (
    <section className={sps2023HomeBlockCss}>
      <article>
        <Sps2023Animation textLines={logoLines} />
      </article>
      <article className={homeInfoCss}>
        <h3>{title}</h3>
        {subtitle}
        <br />
        &nbsp;
        <br />
        <h5>{label}</h5>
        {renderRichText(body)}
        <br />
        <Button {...callToAction} />
      </article>
    </section>
  );
};
