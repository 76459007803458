import gql from 'graphql-tag';

import type { ButtonData } from '../../../../../components/Button';
import { buttonFragment } from '../../../../../components/Button';
import type { ContentfulTypedSysProps } from '../../../../../types/contentful';
import type { RichText } from '../../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../../utils/contentful/contentfulQuery';

export const spsHome2024Query = gql`
  query SpsHome2024Query($id: String!, $locale: String!, $preview: Boolean!) {
    spsHome2024(id: $id, locale: $locale, preview: $preview) {
      ...ContentfulSysId
      logoLines
      title
      subtitle
      label
      blurbOne {
        json
      }
      blurbOneButton {
        ...ButtonAll
      }
      blurbTwo {
        json
      }
      blurbTwoButton {
        ...ButtonAll
      }
    }
  }
  ${contentfulSysIdFragment}
  ${buttonFragment}
`;

export interface SpsHome2024Props {
  spsHome2024: ContentfulTypedSysProps<'SpsHome2024'> & {
    logoLines: string[];
    title: string;
    subtitle: string;
    label: string;
    blurbOne: RichText;
    blurbOneButton: ButtonData;
    blurbTwo: RichText;
    blurbTwoButton: ButtonData;
  };
}
