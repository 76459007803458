import { css } from '@emotion/css';
import type { FormBody } from '@snapchat/snap-design-system-marketing';
import { Form, Input } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useState } from 'react';

import { Config } from '../../../../config';

const containerCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const defaultFileName = 'Sps2023Urls.csv';

interface CsvData {
  fileName: string;
  data: string;
}

/**
 * Returns CsvData Object where fileName is {fileName}.csv and csvData is comma seperates string
 *
 * @param fileName FileName with or wihout csv file extension
 * @param headers Csv headers
 * @param data Record with key being first column and value being second
 * @returns CsvData
 */
export const getSpsCsvData = (
  fileName: string,
  headers: string[],
  data: Record<string, string>
): CsvData => {
  const extensionRegex = /(?:\.([^.]+))?$/;

  if (!fileName) {
    fileName = defaultFileName;
  }

  if (extensionRegex.exec(fileName)?.[1] !== 'csv') {
    // If filename includes different file extension throw exception
    if (extensionRegex.exec(fileName)?.[1]) {
      throw new Error('file extension must be .csv or left blank');
    }
    fileName += '.csv';
  }

  let csvData = headers.join(',') + '\n';

  for (const [key, value] of Object.entries(data)) {
    csvData += `${key},https://www.snappartnersummit.com/irl?registration_token=${value}\n`;
  }

  return { fileName, data: csvData };
};

export const EmailCodeGenerator: FC = () => {
  const [error, setError] = useState('');

  // This component should only render in staging/dev deployments
  if (Config.isDeploymentTypeProd) {
    return null;
  }

  const handleSubmitSuccess = async (response: Response, body: FormBody) => {
    setError('');
    const data = await response.json();

    if (response.status === 400) {
      setError(data);
      return;
    }

    let fileName, csvData;

    try {
      const headers = ['Email', 'URL'];
      // TODO: fix this in subsequent ticket: ENTWEB-7221
      const spsCsv = getSpsCsvData(body.fileName as string, headers, data);
      fileName = spsCsv.fileName;
      csvData = spsCsv.data;
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }

    if (!fileName || !csvData) return;

    const downloadAnchor = document.createElement('a');
    downloadAnchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);

    downloadAnchor.download = fileName;
    downloadAnchor.click();
  };

  return (
    <div className={containerCss}>
      <Form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmitSuccess={handleSubmitSuccess}
        endpoint="api/encrypt_emails"
        submitText="Download"
      >
        <Input
          name="emails"
          label="Email List"
          placeholder="Add list of emails seperated by new lines"
          type="Textarea"
          required
        />
        <Input
          name="fileName"
          label="Output File Name (optional)"
          placeholder={defaultFileName}
          type="Text"
        />
      </Form>
      {error && `There are errors with these emails: ${error}. Please fix and try again`}
    </div>
  );
};
