import { gql } from '@apollo/client';

import type { ContentfulSysProps, ContentfulTypedSysProps } from '../../../../types/contentful';
import type { Items } from '../../../../types/Items';
import { contentfulSysIdFragment } from '../../../../utils/contentful/contentfulQuery';
import type { BookmarkV2Props } from '../BookmarkToast/bookmarkV2Query';
import { bookmarkV2Fragment } from '../BookmarkToast/bookmarkV2Query';
import type { HighlightProps } from '../Highlight/Highlight.types';
import { hightlightFragment } from '../Highlight/HighlightsQuery';
import type { StreamProps } from '../Stream/streamQuery';
import { streamFragment } from '../Stream/streamQuery';

// ============================================================================
// Event all fragment
// ============================================================================

export type SpsEventState = 'pre' | 'post' | 'live';

export const spsEventFragment = gql`
  fragment SpsEventAll on SpsEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    backupRedirectUrl
    bookmarks {
      ...ContentfulSysId
      bookmarksCollection(limit: 30) {
        items {
          ...BookmarkV2All
        }
      }
    }
    highlightsSectionTitle
    highlightsCollection {
      items {
        ...SpsHighlightAll
      }
    }
    stream {
      ...SpsStreamAll
    }
    preEventBlocksCollection {
      items {
        ...ContentfulSysId
      }
    }
    analyticsId
  }
  ${contentfulSysIdFragment}
  ${bookmarkV2Fragment}
  ${hightlightFragment}
  ${streamFragment}
`;

/**
 * Event on the SPS website. We usually have one for each year and a few test ones.
 *
 * NOTE: Not calling this one "Event..." to avoid this coming up as a suggestion when writing
 * generic event code.
 */
export interface SpsEventProps extends ContentfulTypedSysProps<'SpsEvent'> {
  sys: {
    id: string;
    publishedVersion: number;
  };
  eventState: SpsEventState;
  isPolling: boolean;
  pollingIntervalMs?: number;
  pageReloadReasons?: string;
  backupRedirectUrl?: string;
  highlightsSectionTitle?: string;
  highlightsCollection: Items<HighlightProps>;
  bookmarks: ContentfulTypedSysProps<'Bookmarks'> & {
    bookmarksCollection: Items<BookmarkV2Props>;
  };
  stream: StreamProps;
  preEventBlocksCollection: Items<ContentfulSysProps>;
  analyticsId: string;
}

// ============================================================================
// Event polled content fragment (used for state transition)
// ============================================================================

export const spsEventRealtimeFragment = gql`
  fragment SpsEventRealtime on SpsEvent {
    ...ContentfulSysId
    sys {
      publishedVersion
      publishedAt
    }
    eventState
    isPolling
    pollingIntervalMs
    pageReloadReasons
    backupRedirectUrl
  }
  ${contentfulSysIdFragment}
`;

export type SpsEventRealtimeProps = Pick<
  SpsEventProps,
  'eventState' | 'isPolling' | 'pollingIntervalMs' | 'pageReloadReasons' | 'backupRedirectUrl'
> & {
  sys: {
    id: string;
    publishedVersion?: number;
    updatedAt?: string; // only in webhook events
    publishedAt?: string; // not in publish (prod) webhook events :(
  };
};

// ============================================================================
// Event all query
// ============================================================================

export const spsEventQuery = gql`
  query SpsEventQuery($id: String!, $preview: Boolean!, $locale: String!) {
    spsEvent(id: $id, preview: $preview, locale: $locale) {
      ...SpsEventAll
    }
  }
  ${spsEventFragment}
`;

export interface SpsEventDataProps {
  spsEvent: SpsEventProps;
}

// ============================================================================
// Event polled content query
// ============================================================================

export const spsEventRealtimeQuery = gql`
  query SpsEventRealtimeQuery($id: String!, $preview: Boolean!, $locale: String!) {
    spsEvent(id: $id, preview: $preview, locale: $locale) {
      ...SpsEventRealtime
    }
  }
  ${spsEventRealtimeFragment}
`;

export interface SpsEventRealtimeDataProps {
  spsEvent?: SpsEventRealtimeProps;
}
