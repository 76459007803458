import type { PropsWithChildren, ReactElement } from 'react';

import type { ContentProps } from '../Content';
import type { TileProps } from '../Tile';
import type { MultiCarouselProps } from './ReactMultiCarousel/types';
import type { GenerateResponsiveProps } from './utils';

export type ChildProps = ContentProps | TileProps;

export interface ItemsSchedule {
  superLargeDesktop?: number;
  desktop?: number;
  tablet?: number;
}

export type CarouselProps = Partial<GenerateResponsiveProps> &
  Omit<MultiCarouselProps, 'responsive'> & {
    children?:
      | ReactElement<PropsWithChildren<ChildProps>>
      | Array<ReactElement<PropsWithChildren<ChildProps>>>;
    autoPlaySpeed?: number;
    arrows?: boolean;
    useCustomArrows?: boolean;
    maxItem?: number;
    isRTL?: boolean;
  };
