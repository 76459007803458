import { cx } from '@emotion/css';
import { CategoryOptInCookie } from '@snapchat/mw-cookie-components/src/components/types';
import type { TooltipPlace } from '@snapchat/snap-design-system-marketing';
import {
  BrowserFeaturesContext,
  Button,
  ButtonType,
  Icon,
  MessageContext,
  Size,
  Tooltip,
} from '@snapchat/snap-design-system-marketing';
import type { AnalyticsConfig } from 'bitmovin-analytics';
import { HlsAdapter, HTMLVideoElementAdapter } from 'bitmovin-analytics';
import type { ErrorData, Events, Fragment, HlsConfig } from 'hls.js';
import Hls from 'hls.js';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import {
  MediaChromeButton,
  MediaControlBar,
  MediaController,
  MediaFullscreenButton,
  MediaLiveButton,
  MediaLoadingIndicator,
  MediaMuteButton,
  MediaPlayButton,
  MediaTextDisplay,
  MediaTimeRange,
  MediaVolumeRange,
} from 'media-chrome/dist/react';
import {
  type FC,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AppContext } from '../../AppContext';
import { logError, logInfo, logUserEvent, logValue, logWarning } from '../../helpers/logging';
import { UserAction } from '../../types/events';
import {
  audioControlsCss,
  buttonWrapperCss,
  captionClassName,
  captionControlBarClassName,
  captionSelectorDoneButtonCss,
  captionSelectorItemActiveCss,
  captionSelectorItemCss,
  captionSelectorPanelCss,
  centeredControlsCss,
  cornerControlsCss,
  eventPlayerHlsCss,
  loadingIndicatorCss,
  mainControlBarClassName,
  selectedControlCss,
  tooltipClassCss,
  topControlsCss,
  volumeToggleClickableClassName,
  volumeToggleDisabledClassName,
} from './EventPlayer.styles';
import { EventPlayerLoadingSpinner } from './EventPlayerLoadingSpinner';
import type {
  EventMedia,
  EventMediaTextTrack,
  IosHtmlVideoElement,
  MetadataCueList,
  TimestampBehavior,
} from './types';

export interface EventPlayerProps {
  media: EventMedia;
  isLiveEvent?: boolean;
  timestampBehavior: TimestampBehavior;
  progressOffset?: number;
  bufferStallTimeout?: number;
  onProgressUpdated?: (timestamp: number) => void;
  onReceiveBookmark?: (bookmarkId: string) => void;
  onPlayStateChange?: (isPlaying: boolean) => void;
  analyticsId: string;
}

/**
 * Depending on wether called on the browser or node, setTimeout will return either a number or a
 * Timeout object. This type will work regardless of the platform.
 */
type TimeoutId = ReturnType<typeof setTimeout>;

/**
 * License key associated with "default-license" of our Bitmovin account. Key can be found in
 * Bitmovin dashboard: https://dashboard.bitmovin.com/analytics/licenses (credentials are in Last
 * Pass under "Bitmovin Analytics")
 */
const bitmovinLicenseKey = 'f0137608-4f59-4afc-a666-93af4bb0f7dd';

/**
 * 15 seconds before the true edge of the live stream is where we'll seek to when the user begins
 * playback. This ensures a healthy buffer at the edge and therefore smoother playback.
 */
const liveEdgeOffsetSeconds = 15;

const defaultBufferStallTimeoutSeconds = 15;

/**
 * Video Player for event streams
 *
 * Powered by Hls.js: https://github.com/video-dev/hls.js
 *
 * Uses MediaChrome for UI layout and styling: https://www.media-chrome.org/
 */
export const LazyEventPlayer: FC<EventPlayerProps> = ({
  media,
  isLiveEvent,
  timestampBehavior,
  progressOffset,
  bufferStallTimeout = defaultBufferStallTimeoutSeconds,
  onProgressUpdated,
  onReceiveBookmark,
  onPlayStateChange,
  analyticsId,
}) => {
  const { currentLocale, cookieManager } = useContext(AppContext);
  const { formatMessage } = useContext(MessageContext);
  const browserFeatures = useContext(BrowserFeaturesContext);
  const hints = browserFeatures.getLowEntropyHints();

  const isOnIos = hints.platform === 'iOS';
  const isMobile = hints.isMobile;

  // ==========================================================================
  // State/Refs
  // ==========================================================================

  const videoRef = useRef<IosHtmlVideoElement | HTMLVideoElement>(null);
  const mediaControllerRef = useRef<HTMLElement>(null);
  const hlsJsRef = useRef<Hls | null>(null);
  const latestFragment = useRef<Fragment | null>(null);
  const bitmovinInitialized = useRef(false);
  const backupTimeoutId = useRef<TimeoutId | null>(null);
  const hasFailedOver = useRef(false);
  const hasFailedOverAsl = useRef(false);
  const latestRecordedProgress = useRef<number | null>(null);

  const [currentSrc, setCurrentSrc] = useState(media.src);
  const [isCaptionSelectorOpen, setIsCaptionSelectorOpen] = useState(false);
  const [currentCaptionCue, setCurrentCaptionCue] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  // Need both state and ref for active caption. State for UI updates, ref for
  // event listeners (since event listeners won't have access to latest state).
  // For that reason, these should only be updated by the proceeding
  // `setActiveCaptionLanguage` function.
  const [activeCaptionLanguageState, _setActiveCaptionLanguageState] = useState<string | null>(
    null
  );
  const activeCaptionLanguageRef = useRef<string | null>(null);

  const isAslOn = currentSrc === media.aslSrc || currentSrc === media.backupAslSrc;

  // Player should use embedded captions only if none of the given text tracks have an external src
  const renderEmbeddedCaptions = media.textTracks?.every(track => track.src === undefined);

  // ==========================================================================
  // Utilities
  // ==========================================================================

  const attemptBackupFailover = useCallback(() => {
    // Cancel any existing backup timeout and reset timeout ID
    if (backupTimeoutId.current) {
      clearTimeout(backupTimeoutId.current);
      backupTimeoutId.current = null;
    }

    // Case 1: User is on standard stream and backup stream is available, time to failover to backup
    if (!isAslOn && media.backupSrc && !hasFailedOver.current) {
      logWarning({
        component: 'EventPlayer',
        message: `Attempting to failover to backup stream`,
      });
      setCurrentSrc(media.backupSrc);
      hasFailedOver.current = true;
      return;
    }

    // Case 2: User is on ASL stream and backup ASL stream is available, time to failover to backup ASL
    if (isAslOn && media.backupAslSrc && !hasFailedOverAsl.current) {
      logWarning({
        component: 'EventPlayer',
        message: `Attempting to failover to backup ASL stream`,
      });
      setCurrentSrc(media.backupAslSrc);
      hasFailedOverAsl.current = true;
      return;
    }

    // Case 3: Already on backup stream or no backup stream available, nothing we can do
    logWarning({
      component: 'EventPlayer',
      message: `No backup stream available`,
    });
  }, [isAslOn, media.backupAslSrc, media.backupSrc]);

  // ==========================================================================
  // Logging callbacks
  // ==========================================================================

  const logFullscreenEvent = useCallback(() => {
    if (document.fullscreenElement) {
      logUserEvent({
        eventAction: UserAction.Fullscreen,
        eventCategory: 'EventPlayer',
        eventLabel: analyticsId,
      });
    } else {
      // User exited fullscreen mode for the video
      logUserEvent({
        eventAction: UserAction.ExitFullscreen,
        eventCategory: 'EventPlayer',
        eventLabel: analyticsId,
      });
    }
  }, [analyticsId]);

  const logMuteEvent = useCallback(() => {
    const isMuted = videoRef.current?.muted ?? false;

    logUserEvent({
      eventAction: isMuted ? UserAction.Mute : UserAction.Unmute,
      eventCategory: 'EventPlayer',
      eventLabel: `${analyticsId}`,
    });
  }, [analyticsId]);

  const logNativeError = useCallback(() => {
    logError({
      component: 'EventPlayer',
      message: `Native playback error`,
      error: videoRef.current?.error,
    });
  }, [videoRef]);

  const logHlsError = useCallback((data: ErrorData) => {
    logError({
      component: 'EventPlayer',
      message: `HLS.js error: ${data.details}`,
    });
  }, []);

  const logPlay = useCallback(() => {
    logUserEvent({
      eventAction: UserAction.Play,
      eventCategory: 'EventPlayer',
      eventLabel: analyticsId,
    });
  }, [analyticsId]);

  const logPause = useCallback(() => {
    logUserEvent({
      eventAction: UserAction.Pause,
      eventCategory: 'EventPlayer',
      eventLabel: analyticsId,
    });
  }, [analyticsId]);

  // Debounced as volume slider dispatches many events in a short amount of time
  const debouncedLogSeeked = useMemo(
    () =>
      debounce(() => {
        const currentTime = videoRef.current?.currentTime ?? 0;

        logUserEvent({
          eventAction: UserAction.Seek,
          eventCategory: 'EventPlayer',
          eventLabel: `${analyticsId}:${currentTime}`,
        });
      }, 4_000),
    [analyticsId]
  );

  // Throttled to avoid spamming logs
  const throttledLogProgress = useMemo(
    () =>
      throttle((currentTime: number) => {
        logValue({
          eventCategory: 'EventPlayer',
          eventLabel: analyticsId,
          eventVariable: 'video_watch',
          eventValue: currentTime,
        });
      }, 5_000),
    [analyticsId]
  );

  // Debounced as volume slider dispatches many events in a short amount of time
  const debouncedLogVolumeChange = useMemo(
    () =>
      debounce(() => {
        const volume = videoRef.current?.volume ?? 0;

        logUserEvent({
          eventAction: UserAction.SetVolume,
          eventCategory: 'EventPlayer',
          eventLabel: `${analyticsId}:${Math.round(volume * 100)}`,
        });
      }, 1_000),
    [analyticsId]
  );

  // ==========================================================================
  // UI Callbacks
  // ==========================================================================

  const setActiveCaptionLanguage = useCallback(
    (languageCode: string | null) => {
      if (!videoRef.current) return;

      // Log if captions are being turned on or off (rather than just switching languages)
      const captionsToggled =
        (languageCode !== null && activeCaptionLanguageRef.current === null) ||
        (languageCode === null && activeCaptionLanguageRef.current !== null);

      if (captionsToggled) {
        logUserEvent({
          eventAction: languageCode ? UserAction.ShowCaptions : UserAction.HideCaptions,
          eventCategory: 'EventPlayer',
          eventLabel: analyticsId,
        });
      }

      // Log if language is being changed
      if (languageCode) {
        logInfo({
          eventAction: 'CaptionsSelected',
          eventCategory: 'EventPlayer',
          eventLabel: `${analyticsId}:${languageCode}`,
        });
      }

      // Clear the caption cue when switching languages to prevent a stale cue
      // from the prior language from hanging around
      setCurrentCaptionCue(null);

      _setActiveCaptionLanguageState(languageCode);
      activeCaptionLanguageRef.current = languageCode;

      // To switch text tracks, first disable all tracks, then enable the one we want.
      // We do it in two separate loops to prevent flickering of the natively displayed subtitle track.
      for (let i = 0; i < videoRef.current.textTracks.length; i++) {
        videoRef.current.textTracks[i]!.mode = 'disabled';
      }

      // Enable the selected text track. It's possible for there to be multiple text tracks with the same language. We
      // only care about the latest track, so we loop backwards and stop when we find a match.
      for (let i = videoRef.current.textTracks.length - 1; i >= 0; i--) {
        const track = videoRef.current.textTracks[i]!;

        if (track.language === languageCode) {
          // 'hidden' mode will hide the native caption display but still loads captions cues. 'showing' mode will both
          // load caption cues and display them natively. On iOS we rely on native captions, so we have to use 'showing'
          // mode, otherwise we use 'hidden' mode since we handle caption cue rendering ourselves.
          track.mode = isOnIos ? 'showing' : 'hidden';
          break;
        }
      }
    },
    [analyticsId, isOnIos]
  );

  const toggleAsl = useCallback(() => {
    if (!videoRef.current || media.aslSrc === undefined) return;

    // Determine which source to switch to based off of current source and state of failover
    const nonAslSource = hasFailedOver.current && media.backupSrc ? media.backupSrc : media.src;
    const aslSource =
      hasFailedOverAsl.current && media.backupAslSrc ? media.backupAslSrc : media.aslSrc;
    const newSource = isAslOn ? nonAslSource : aslSource;

    setCurrentSrc(newSource);

    logUserEvent({
      eventAction: UserAction.Click,
      eventCategory: 'EventPlayer',
      eventLabel: `${analyticsId}:${isAslOn ? 'ASL' : 'main'}`,
    });
  }, [media.aslSrc, media.backupSrc, media.src, media.backupAslSrc, isAslOn, analyticsId]);

  const closeCaptionSelector = useCallback(() => {
    setIsCaptionSelectorOpen(false);
    // The "userinactive" attribute is used by MediaChrome to hide the UI. We set it here
    // manually so that the controls hides themselves right after the caption selector is closed.
    // Without this, the controls would stay visible until the user interacts with the player (moves
    // the mouse, clicks a control, etc.)
    mediaControllerRef.current?.setAttribute('userinactive', '');
  }, []);

  // ==========================================================================
  // Event Listener Callbacks
  // ==========================================================================

  const onTextTracksChange = useCallback(() => {
    if (!videoRef.current) return;

    for (let i = 0; i < videoRef.current.textTracks.length; i++) {
      const track = videoRef.current.textTracks[i];

      if (track?.mode === 'showing' && activeCaptionLanguageRef.current !== track.language) {
        const language = track.language === '' ? null : track.language;
        setActiveCaptionLanguage(language ?? null);
      }
    }
  }, [setActiveCaptionLanguage]);

  const onCueChangeCaption = useCallback((event: Event) => {
    const currentTrack = event.target as TextTrack;

    // Set the caption position and alignment of all active captions so that even if player is rendering native captions,
    // they are positioned correctly.
    for (let i = 0; i < currentTrack!.cues!.length; i++) {
      const cue = currentTrack.cues?.[i] as VTTCue | undefined;

      if (!cue) continue;

      // Set anchor point of caption cue to the center of the caption
      cue.positionAlign = 'center';
      cue.align = 'center';

      // Set horizontal position of caption to the center of the video (50% of video width)
      cue.position = 50;

      // Make line (aka vertical position of caption) a percentage value of the video height
      cue.snapToLines = false;

      // Caption looks good about 75% down the screen
      cue.line = 75;
    }

    // We only want to display the latest caption cue, so we remove all but the most recent
    for (let i = currentTrack!.activeCues!.length - 1; i > 0; i--) {
      currentTrack!.removeCue(currentTrack!.activeCues![i]!);
    }

    if (currentTrack.language === activeCaptionLanguageRef.current) {
      const activeCue = currentTrack.activeCues?.[0] as VTTCue;
      setCurrentCaptionCue(activeCue?.text ?? null);
    }
  }, []);

  const onCueChangeID3 = useCallback(
    (cueChangeEvent: Event) => {
      const eventTarget = cueChangeEvent.target as TextTrack;
      const activeCues = eventTarget.activeCues as unknown as MetadataCueList;

      if (!activeCues || activeCues.length === 0) return;

      for (let i = 0; i < activeCues.length; i++) {
        const cue = activeCues[i];

        // We only care about cues that have string data, anything else is not a bookmark
        if (typeof cue?.value.data !== 'string') {
          return;
        }

        const metadataValue: string = cue.value.data;
        const [_, contentType, bookmark] = metadataValue.split(':');

        if (contentType === 'bookmarkV2' && bookmark) {
          onReceiveBookmark?.(bookmark);
        }

        if (contentType !== 'bookmarkV2') {
          logError({
            component: 'EventPlayer',
            message: `Received content type ${contentType} instead of content type bookmarkV2.`,
          });
        }
      }
    },
    [onReceiveBookmark]
  );

  const onTrackAdded = useCallback(
    (addTrackEvent: TrackEvent) => {
      const track = addTrackEvent.track;

      if (!track) return;

      // Subscribe to cue change events for...
      // - captions tracks to render captions
      // - metadata tracks to handle ID3 cues (bookmarks)
      if (track.kind === 'captions') {
        // Disable new captions tracks by default, we'll enable them when the user selects them
        track.mode = 'disabled';

        track.addEventListener('cuechange', onCueChangeCaption);

        // If the new track happens to match the active caption language, enable it right away
        track.language === activeCaptionLanguageRef.current &&
          setActiveCaptionLanguage(activeCaptionLanguageRef.current);
      } else if (track.kind === 'metadata') {
        // Only visible tracks should be caption tracks
        track.mode = 'hidden';
        track.addEventListener('cuechange', onCueChangeID3);
      }
    },
    [onCueChangeCaption, onCueChangeID3, setActiveCaptionLanguage]
  );

  const seekToLiveEdge = useCallback(() => {
    if (!videoRef.current) return;
    const seekable = videoRef.current.seekable;

    // Media is not seekable
    if (!seekable) return;

    // Media is seekable, but there are no seekable ranges (no segments buffered)
    if (!seekable.length) return;

    // We seek as far as we can forward, and then back off by a few seconds so there so we leave some buffered segments
    // available enabling smoother playback after the seek.
    videoRef.current.currentTime = seekable.end(seekable.length - 1) - liveEdgeOffsetSeconds;
  }, []);

  const updatePlayState = useCallback(() => {
    const isPlaying = videoRef.current?.paused === false;
    setIsPlaying(isPlaying);
    onPlayStateChange?.(isPlaying);
  }, [setIsPlaying, onPlayStateChange]);

  const onTimeUpdate = useCallback(() => {
    if (!videoRef.current || !onProgressUpdated) return;

    // Record latest progress so player can know where to seek to if the user toggles ASL
    latestRecordedProgress.current = videoRef.current.currentTime;

    let currentTimeMs = 0;

    if (timestampBehavior === 'Use Manifest Timestamps') {
      // For "Use Manifest Timestamps", we need to calculate the current time based on the timestamps embedded in the
      // segments. On iOS, we can use native APIs to retrieve this, but for other platform we'll have to rely
      // on Hls.js.
      if (isOnIos) {
        // On iOS, the video element has a `getStartDate` method that returns the embedded timestamp on the first
        // received fragment. The `currentTime` property of the video element will be the number of seconds elapsed plus
        // some offset, but it appears that `getStartDate` takes this offset into account; therefore, we can simply
        // sum `getStartDate` and `currentTime` to get an accurate current timestamp.
        //
        // current timestamp = (timestamp of first segment) + (total seconds elapsed)
        const videoElement = videoRef.current as IosHtmlVideoElement;
        currentTimeMs = videoElement.getStartDate().getTime() + videoElement.currentTime * 1000;
        onProgressUpdated(Math.floor(currentTimeMs));
      } else {
        // On other platforms, we'll have to rely on Hls.js. We can derive the current timestamp by taking the latest
        // fragment's timestamp, and then adding the seconds elapsed since that fragment started. Each fragment has a
        // relative start time (i.e. fragment started x seconds since user tuned in), so we can subtract the fragment
        // start time from total seconds elapsed (i.e. `currentTime`) to get the number of seconds elapsed since the
        // fragment began.
        //
        //  current timestamp = (latest segment timestamp) + (total seconds elapsed) - (relative fragment start time)
        currentTimeMs =
          (latestFragment.current?.programDateTime ?? 0) +
          videoRef.current.currentTime * 1000 -
          (latestFragment.current?.start ?? 0) * 1000;
        onProgressUpdated(Math.floor(currentTimeMs));
      }
    } else if (timestampBehavior === 'Use Calculated Timestamps') {
      // For "Use Calculated Timestamps", we use the raw `currentTime` property of the video element and add
      // the configured offset (if any).
      currentTimeMs = (videoRef.current?.currentTime + (progressOffset ?? 0)) * 1000;
      onProgressUpdated(currentTimeMs);
    } else {
      // timestampBehavior === 'Use Progress Timestamps'
      // For "Use Progress Timestamps", we use the raw `currentTime` property of the video element
      // with no additions or alterations.
      currentTimeMs = videoRef.current?.currentTime * 1000;
      onProgressUpdated(currentTimeMs);
    }

    const roundedToNearestSecond = Math.floor(currentTimeMs / 1000);

    throttledLogProgress(roundedToNearestSecond);
  }, [onProgressUpdated, timestampBehavior, throttledLogProgress, isOnIos, progressOffset]);

  // ==========================================================================
  // Error Handling
  // ==========================================================================

  const onNativeError = useCallback(() => {
    // 1: MEDIA_ERR_ABORTED - fetching process aborted by user
    // 2: MEDIA_ERR_NETWORK - error occurred when downloading
    // 3: MEDIA_ERR_DECODE - error occurred when decoding
    // 4: MEDIA_ERR_SRC_NOT_SUPPORTED - audio/video not supported
    //
    // ABORTED errors are a result of user abandoning playback and do not call for failover
    if (videoRef.current?.error?.code === 1) return;

    // Other errors are most likely fatal, so we can try to failover to a backup stream
    attemptBackupFailover();
  }, [attemptBackupFailover]);

  const onHlsError = useCallback(
    (_: Events.ERROR, data: ErrorData) => {
      logHlsError(data);

      // If the error is not fatal, hls.js will attempt to recover on its own
      if (!data.fatal) return;

      // For fatal errors, we can try to recover manually

      // A media error is a generic error that can encompass a variety of issues, some of them temporary. Therefore,
      // we can try to recover from a media error by calling `recoverMediaError` which detaches and then
      // reattaches the <video /> element to the Hls instance.
      if (data.type === Hls.ErrorTypes.MEDIA_ERROR) {
        logWarning({
          component: 'EventPlayer',
          message: `Attempting to recover from media error`,
        });
        hlsJsRef.current?.recoverMediaError();
        return;
      }

      // If the error is not recoverable, we can try to failover to a backup stream
      attemptBackupFailover();
    },
    [attemptBackupFailover, logHlsError]
  );

  const onWaiting = useCallback(() => {
    // If we're already waiting on a current timeout, we don't need to set another one
    if (backupTimeoutId.current) return;

    // If buffer stalls for longer than the configured threshold, we can try to failover to a backup stream
    backupTimeoutId.current = setTimeout(() => {
      logWarning({
        component: 'EventPlayer',
        message: `Buffer stall detected`,
      });

      attemptBackupFailover();
    }, bufferStallTimeout * 1000);
  }, [attemptBackupFailover, bufferStallTimeout]);

  const onCanPlay = useCallback(() => {
    // "canplay" event fires when player is has successfully buffered enough data to begin playback.
    // We can clear the backup timeout as player can now progress.
    if (backupTimeoutId.current) {
      clearTimeout(backupTimeoutId.current);
      backupTimeoutId.current = null;
    }
  }, []);

  // ==========================================================================
  // Initializers
  // ==========================================================================

  const initializeHls = useCallback(() => {
    if (!videoRef.current) {
      return;
    }

    if (Hls.isSupported()) {
      const hlsConfig: Partial<HlsConfig> = {
        // Enabling CMCD (Common Media Client Data) will attach client side data to video segment GET requests that the
        // CDN can ingest and then use top optimize delivery and for analytics purposes.
        // (https://ottverse.com/common-media-client-data-cmcd/)
        cmcd: {
          contentId: media.title,
        },

        // Keep buffer length relatively small (default is 30 seconds) to allows for faster failover to backup streams
        maxBufferLength: 30,

        // We don't support seeking/rewinding for live events, so we can set the back buffer length to 0 to keep as
        // little in memory as possible.
        backBufferLength: 0,
      };

      // If we're using embedded captions, we have to tell HLS.js what each caption track is.
      // HLS.js supports a maximum of 4 embedded caption tracks.
      if (media.textTracks && renderEmbeddedCaptions) {
        hlsConfig.captionsTextTrack1Label = media.textTracks[0]?.label;
        hlsConfig.captionsTextTrack1LanguageCode = media.textTracks[0]?.languageCode;

        hlsConfig.captionsTextTrack2Label = media.textTracks[1]?.label;
        hlsConfig.captionsTextTrack2LanguageCode = media.textTracks[1]?.languageCode;

        hlsConfig.captionsTextTrack3Label = media.textTracks[2]?.label;
        hlsConfig.captionsTextTrack3LanguageCode = media.textTracks[2]?.languageCode;

        hlsConfig.captionsTextTrack4Label = media.textTracks[3]?.label;
        hlsConfig.captionsTextTrack4LanguageCode = media.textTracks[3]?.languageCode;
      }

      const hls = new Hls(hlsConfig);
      hls.loadSource(currentSrc);
      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.FRAG_CHANGED, (_, data) => {
        latestFragment.current = data.frag;
      });

      hls.on(Hls.Events.ERROR, onHlsError);

      // Disable native caption display, we'll display them using our own UI
      hls.subtitleDisplay = false;

      hlsJsRef.current = hls;
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      // Hls.js is not supported on this browser, but we can try to use native HLS support
      // (this is the case for all iOS devices)
      videoRef.current.src = currentSrc;
    } else {
      // Hls.js not supported and native HLS playback is not available
      logInfo({
        eventAction: 'HLS playback failed',
        eventCategory: 'EventPlayer',
        eventLabel: analyticsId,
      });

      return;
    }

    // For VODs, we should check if we have any current recorded progress and seek to that point
    // if possible so user does not lose progress when swapping between ASL and main stream.
    if (!isLiveEvent && latestRecordedProgress.current)
      videoRef.current.currentTime = latestRecordedProgress.current;
  }, [
    isLiveEvent,
    media.title,
    media.textTracks,
    renderEmbeddedCaptions,
    currentSrc,
    onHlsError,
    analyticsId,
  ]);

  const cleanUpCaptions = useCallback(
    (videoElement: HTMLVideoElement) => {
      videoElement.textTracks.removeEventListener('change', onTextTracksChange);

      for (let i = 0; i < videoElement.textTracks.length; i++) {
        videoElement.textTracks[i]?.removeEventListener('cuechange', onCueChangeCaption);
      }
    },
    [onCueChangeCaption, onTextTracksChange]
  );

  const initializeEventLogs = useCallback(() => {
    if (!videoRef.current) return;

    videoRef.current?.addEventListener('error', logNativeError);
    videoRef.current?.addEventListener('play', logPlay);
    videoRef.current?.addEventListener('pause', logPause);
    videoRef.current?.addEventListener('volumechange', debouncedLogVolumeChange);
    videoRef.current?.addEventListener('seeked', debouncedLogSeeked);

    // iOS Safari doesn't support the `fullscreenchange` event, but it does support `webkitfullscreenchange`
    // except for the iPhone which supports no fullscreen events at all.
    document.addEventListener('webkitfullscreenchange', logFullscreenEvent);
    document.addEventListener('fullscreenchange', logFullscreenEvent);
  }, [
    logNativeError,
    logPlay,
    logPause,
    debouncedLogVolumeChange,
    debouncedLogSeeked,
    logFullscreenEvent,
  ]);

  const cleanupEventLogs = useCallback(
    (videoElement: HTMLVideoElement) => {
      document.removeEventListener('fullscreenchange', logFullscreenEvent);
      document.removeEventListener('webkitfullscreenchange', logFullscreenEvent);

      videoElement.removeEventListener('error', logNativeError);
      videoElement.removeEventListener('play', logPlay);
      videoElement.removeEventListener('pause', logPause);
      videoElement.removeEventListener('volumechange', debouncedLogVolumeChange);
      videoElement.removeEventListener('seeked', debouncedLogSeeked);
    },
    [
      debouncedLogSeeked,
      debouncedLogVolumeChange,
      logFullscreenEvent,
      logNativeError,
      logPause,
      logPlay,
    ]
  );

  const initializeBitmovinAnalytics = useCallback(() => {
    if (!videoRef.current || bitmovinInitialized.current) return;

    // We can only run Bitmovin with cookies if user has opted into performance monitoring. Analytics without
    // cookies is still robust, but will not be able to track user between sessions, and the "Unique Users" metric
    // will be inaccurate.
    const acceptedPerformance =
      cookieManager.getCookie(CategoryOptInCookie.Essential) === 'true' &&
      cookieManager.getCookie(CategoryOptInCookie.Performance) === 'true';

    const config: AnalyticsConfig = {
      key: bitmovinLicenseKey,
      videoId: analyticsId,
      title: media.title,
      isLive: isLiveEvent,
      config: {
        // TODO (ENTWEB-8818): Find a way to initialize/re-initialize bitmovin in a way that allows
        // it to  adjust  to a user's Cookie Modal election (i.e. if a user opts into performance
        // monitoring  the  bitmovin instance should be re-initialized with cookiesEnabled: true) OR
        // find a way to maintain "Unique Users" metric without cookies or with essential cookies
        // only.
        cookiesEnabled: acceptedPerformance,
        cookiesMaxAge: 31536000, // 1 year
      },
    };

    // Adapter constructor will initiate analytics collection. We don't
    // need the returned adapter instance for our use cases.
    const _adapter = hlsJsRef.current
      ? new HlsAdapter(config, hlsJsRef.current)
      : new HTMLVideoElementAdapter(config, videoRef.current);
    bitmovinInitialized.current = true;
  }, [analyticsId, isLiveEvent, media.title, cookieManager]);

  // ==========================================================================
  // Effects
  // ==========================================================================

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    // We can tell if the media is an hls stream by checking for an m3u8 file extension (while
    // ignoring any query params).
    const isHls = currentSrc.match(/\w+\.m3u8(?=\?|$)/);

    // If the media is an HLS stream, we initialize hls playback. Otherwise, we can just set the the
    // src attribute directly and hope for the best.
    if (isHls) {
      initializeHls();
    } else {
      videoRef.current.src = currentSrc;
    }

    initializeEventLogs();
    initializeBitmovinAnalytics();

    videoRef.current.addEventListener('timeupdate', onTimeUpdate);

    videoRef.current.textTracks.addEventListener('addtrack', onTrackAdded);

    // Track play/pause state so that UI can be updated appropriately
    videoRef.current.addEventListener('play', updatePlayState);
    videoRef.current.addEventListener('pause', updatePlayState);

    // For live video we want to automatically seek to the live edge of the video when playback begins
    // TODO: Confirm with GBX what the desired behavior is for SPS 2024
    isLiveEvent && videoRef.current.addEventListener('play', seekToLiveEdge);

    // For native playback, errors are handled by the video element error event callback
    !hlsJsRef.current && videoRef.current.addEventListener('error', onNativeError);

    // use "waiting" and "progress" callbacks to determine if the video has gotten stuck buffering
    // in which case the player will attempt to failover to a backup stream
    videoRef.current.addEventListener('waiting', onWaiting);
    videoRef.current.addEventListener('canplay', onCanPlay);

    // In case a caption language has already been selected prior to the video being loaded or the viewer is switching
    // between two streams, we need to ensure that the active caption language is maintained.
    setActiveCaptionLanguage(activeCaptionLanguageRef.current);

    // iOS full screen player has its own caption selector. To ensure our custom caption selector
    // stays up to date, we need to listen for changes to the native caption selector and update our
    // custom caption selector to match.
    isOnIos && videoRef.current.textTracks.addEventListener('change', onTextTracksChange);

    // Used for clean up since videoRef may have changed by the time the clean up fires
    const videoRefCurrent = videoRef.current;
    const hlsJsRefCurrent = hlsJsRef.current;

    return () => {
      // Cancel debounced calls
      debouncedLogVolumeChange.cancel();
      debouncedLogSeeked.cancel();

      // Clean up log, captions, and hls.js listeners
      cleanupEventLogs(videoRefCurrent);
      cleanUpCaptions(videoRefCurrent);
      hlsJsRefCurrent?.destroy();

      // Clean up ID3 event listeners
      videoRefCurrent?.textTracks.removeEventListener('addtrack', onTrackAdded);

      for (let i = 0; i < videoRefCurrent.textTracks.length; i++) {
        videoRefCurrent.textTracks[i]?.removeEventListener('cuechange', onCueChangeID3);
      }

      // Clean up play/pause listeners
      videoRefCurrent?.removeEventListener('play', seekToLiveEdge);
      videoRefCurrent?.removeEventListener('play', updatePlayState);
      videoRefCurrent?.removeEventListener('pause', updatePlayState);
      videoRefCurrent?.removeEventListener('timeupdate', onTimeUpdate);

      // Clean up error handling/buffering listeners
      videoRefCurrent?.removeEventListener('waiting', onWaiting);
      videoRefCurrent?.removeEventListener('canplay', onCanPlay);
      videoRefCurrent?.removeEventListener('error', onNativeError);
    };
  }, [
    initializeHls,
    initializeEventLogs,
    logFullscreenEvent,
    initializeBitmovinAnalytics,
    logNativeError,
    logPlay,
    logPause,
    onTrackAdded,
    onCueChangeID3,
    onTextTracksChange,
    onCueChangeCaption,
    onTimeUpdate,
    debouncedLogVolumeChange,
    debouncedLogSeeked,
    cleanupEventLogs,
    cleanUpCaptions,
    isLiveEvent,
    seekToLiveEdge,
    updatePlayState,
    media.src,
    onNativeError,
    onWaiting,
    onCanPlay,
    currentSrc,
    isOnIos,
    setActiveCaptionLanguage,
  ]);

  // ==========================================================================
  // UI
  // ==========================================================================

  const getCaptionSelectorContent = () => {
    const languageItems = media.textTracks?.map(track => {
      const label = getLabelFromTrack(track, currentLocale);
      return (
        <div
          key={track.languageCode}
          className={cx(captionSelectorItemCss, {
            [captionSelectorItemActiveCss]: activeCaptionLanguageState === track.languageCode,
          })}
          onClick={() => setActiveCaptionLanguage(track.languageCode)}
          role="menuitem"
        >
          <span>{label}</span>
          <span>
            {track.languageCode === activeCaptionLanguageState && (
              <Icon name="check" fill="white" size={20} />
            )}
          </span>
        </div>
      );
    });

    const noCaptionsItem = (
      <div
        key="no-captions"
        className={cx(captionSelectorItemCss, {
          [captionSelectorItemActiveCss]: activeCaptionLanguageState === null,
        })}
        onClick={() => setActiveCaptionLanguage(null)}
        role="menuitem"
      >
        <span>{captionsOffLabel}</span>
        <span>
          {activeCaptionLanguageState === null && <Icon name="check" fill="white" size={20} />}
        </span>
      </div>
    );

    return (
      <>
        {noCaptionsItem}
        {languageItems}
        <Button
          className={captionSelectorDoneButtonCss}
          type={ButtonType.Primary}
          size={Size.Compact}
          onClick={closeCaptionSelector}
        >
          {captionsCloseLabel}
        </Button>
      </>
    );
  };

  const getMuteButtonContent = () => (
    <>
      <img title="sound on" slot="high" src="/sound-on.svg" />
      <img title="sound on" slot="medium" src="/sound-on.svg" />
      <img title="sound on" slot="low" src="/sound-on.svg" />
      <img title="sound off" slot="off" src="/sound-off.svg" />
    </>
  );

  // Tooltips should not be displayed on mobile. Here we create a variable "TooltipComponent" which
  // will be either the "EventPlayerTooltip" component (on desktop) or a dummy component that simply
  // renders its children (on mobile).
  const NoTooltip: FC<CustomTooltipProps> = ({ children }) => <>{children}</>;
  const TooltipComponent = isMobile ? NoTooltip : EventPlayerTooltip;

  // Localized control labels
  const playLabel = formatMessage({ id: 'videoPlayerPlay', defaultMessage: 'Play' });
  const pauseLabel = formatMessage({ id: 'videoPlayerPause', defaultMessage: 'Pause' });
  const toggleFullscreenLabel = formatMessage({
    id: 'videoPlayerToggleFullscreen',
    defaultMessage: 'Toggle Fullscreen',
  });
  const closedCaptionsLabel = formatMessage({
    id: 'videoPlayerCaptions',
    defaultMessage: 'Closed Captions',
  });
  const captionsOffLabel = formatMessage({
    id: 'videoPlayerCaptionsOff',
    defaultMessage: 'Off',
  });
  const captionsCloseLabel = formatMessage({
    id: 'videoPlayerCaptionsClose',
    defaultMessage: 'Close',
  });

  return (
    <div className={eventPlayerHlsCss} data-testid="event-player-container">
      {/* UI should never hide if caption selector is open */}
      <MediaController
        ref={mediaControllerRef}
        autoHide={isCaptionSelectorOpen ? -1 : 4}
        data-testid="event-player-controller"
      >
        <MediaLoadingIndicator className={loadingIndicatorCss}>
          <div slot="icon">
            <EventPlayerLoadingSpinner />
          </div>
        </MediaLoadingIndicator>
        {/* `playsInline` prevent video from starting in full screen on Safari */}
        <video slot="media" ref={videoRef} crossOrigin="anonymous" playsInline autoPlay>
          {!renderEmbeddedCaptions &&
            media.textTracks?.map(track => (
              <track
                id={`${track.languageCode}-track`}
                key={track.languageCode}
                srcLang={track.languageCode}
                src={track.src}
                kind={track.kind}
                label={getLabelFromTrack(track, currentLocale)}
              />
            ))}
        </video>

        {/* Top control bar (for live indicator) */}
        {isLiveEvent && (
          <MediaControlBar slot="top-chrome" className={topControlsCss}>
            <MediaLiveButton />
          </MediaControlBar>
        )}

        {/* Giant play button (mobile only) */}
        <div slot="centered-chrome" className={centeredControlsCss}>
          <MediaPlayButton>
            <img title="play" slot="play" src="/play.svg" />
            <img title="pause" slot="pause" src="/pause.svg" />
          </MediaPlayButton>
        </div>

        {/* Captions control bar (ignore for iOS since we fallback to native in that case) */}
        {!isOnIos && currentCaptionCue && activeCaptionLanguageState !== null && (
          <MediaControlBar className={captionControlBarClassName}>
            <MediaTextDisplay className={captionClassName}>{currentCaptionCue}</MediaTextDisplay>
          </MediaControlBar>
        )}

        {/* Bottom control bar (holds most controls) */}
        <MediaControlBar className={mainControlBarClassName}>
          <TooltipComponent text={isPlaying ? pauseLabel : playLabel} place="top">
            <MediaPlayButton>
              <img title="play" slot="play" src="/play.svg" />
              <img title="pause" slot="pause" src="/pause.svg" />
            </MediaPlayButton>
          </TooltipComponent>
          {!isLiveEvent && <MediaTimeRange />}
          <div className={cornerControlsCss}>
            <TooltipComponent text={toggleFullscreenLabel} place="top">
              <MediaFullscreenButton data-testid="event-player-fullscreen-control">
                <img title="fullscreen on" slot="enter" src="/full-screen-on.png" />
                <img title="fullscreen off" slot="exit" src="/full-screen-off.svg" />
              </MediaFullscreenButton>
            </TooltipComponent>
            {/* The following element is for audio control (muting video and changing volume). It works slightly
            differently between mobile and desktop...

            Desktop: The volume range is visible when the mute button is hovered. If the user clicks the the mute button
            it will immediately mute the video.

            Mobile: If the user taps the mute button, it will reveal the volume range but it will NOT mute the video.
            Once the volume range is open, only then will the mute button actually mute the video. Essentially, on
            mobile, the mute button doubles as a trigger to open the volume range.

            To achieve this functionality, we render two mute buttons. One is disabled and does NOT actually mute the
            video. The other is clickable and does mute mute the video. The clickable button is swapped in (via css
            rules) when the audio controls are hovered. The swap makes it so that on mobile, the initial tap does not
            mute the video, but instead triggers the hover which then swaps in the clickable button. */}
            <div className={audioControlsCss}>
              <div
                className={buttonWrapperCss}
                onClick={logMuteEvent}
                data-testid="event-player-mute-control"
              >
                <MediaMuteButton className={volumeToggleClickableClassName}>
                  {getMuteButtonContent()}
                </MediaMuteButton>
                <MediaMuteButton className={volumeToggleDisabledClassName} disabled>
                  {getMuteButtonContent()}
                </MediaMuteButton>
              </div>
              <MediaVolumeRange data-testid="event-player-volume-control" />
            </div>

            {media.aslSrc && (
              <TooltipComponent text="ASL" place="left">
                <div className={buttonWrapperCss} onClick={toggleAsl}>
                  <MediaChromeButton className={cx({ [selectedControlCss]: isAslOn })}>
                    <img title="toggle asl" src="/asl.svg" />
                  </MediaChromeButton>
                </div>
              </TooltipComponent>
            )}

            {media.textTracks && (
              <TooltipComponent text={closedCaptionsLabel} place="left">
                {/* // Using wrapper here since Media Chrome elements don't take onClick property */}
                <div className={buttonWrapperCss} onClick={() => setIsCaptionSelectorOpen(true)}>
                  <MediaChromeButton data-testid="event-player-captions-button">
                    <img title="toggle captions" src="/closed-captions.svg" />
                  </MediaChromeButton>
                </div>
              </TooltipComponent>
            )}
          </div>
        </MediaControlBar>
        {/* Caption Selector */}
        {isCaptionSelectorOpen && (
          <MediaTextDisplay
            className={captionSelectorPanelCss}
            data-testid="event-player-captions-controls"
          >
            {getCaptionSelectorContent()}
          </MediaTextDisplay>
        )}
      </MediaController>
    </div>
  );
};

/**
 * Get the label for a text track First checks if a label has been explicitly specified, otherwise,
 * uses JavaScript's native `Intl.DisplayNames` to get the language name.
 */
const getLabelFromTrack = (track: EventMediaTextTrack, clientLocale: string) => {
  if (track.label) {
    return track.label;
  }

  if (track.languageCode) {
    const languageNames = new Intl.DisplayNames([clientLocale], {
      type: 'language',
    });

    return languageNames.of(track.languageCode) ?? 'Unknown';
  }

  return 'Unknown';
};

interface CustomTooltipProps {
  place: TooltipPlace;
  text: string;
  children: ReactNode;
}

/** Helper component to disable tooltips on mobile */
const EventPlayerTooltip: FC<CustomTooltipProps> = ({ place, text, children }) => (
  <Tooltip
    // Immediately hide tooltip after mousing out
    delayHide={0}
    place={place}
    // Interacting with the controls should hide the tooltip
    globalEventOff="click"
    // Tooltip doesn't handle spaces in "uniqueId" attribute so we remove them here before passing along text
    // as the uniqueId.
    uniqueId={text.replace(/\s/g, '')}
    content={<MediaTextDisplay>{text}</MediaTextDisplay>}
    tooltipClassName={tooltipClassCss}
  >
    {children}
  </Tooltip>
);
