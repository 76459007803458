import { css } from '@emotion/css';
import { Black } from '@snapchat/snap-design-system-icons/lib/constants/colors';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const spsLoginButtonCss = css`
  button {
    color: ${Black.V200};
    cursor: pointer;
    width: unset;
    min-width: 60px; /* For when the text collapses while loading */
    height: 48px;
    background-position: 21px 14px;
    background-size: 19px;
    padding: 0 20px 2px 54px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    /* Resetting the styles from login kit. */
    span {
      padding-left: unset;
      font-size: unset;
      font-weight: unset;
    }

    /* Disables the loading animation */
    animation-iteration-count: 0;

    /* When the login kit removes the inner span, put in placeholder text. */
    &:empty::after {
      content: 'Authenticating...';
    }
  }

  /* Per mocks, the mobile font-size is actually bigger */
  ${mobileMediaQuery} {
    button span {
      font-size: 18px;
    }
  }

  div {
    width: fit-content;
  }
`;
