import { Button } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useRef, useState } from 'react';

import { IntoHeaderPortal } from '../../../../../components/Header/HeaderPortal';
import type { ContentfulIdVariable } from '../../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../../hooks/useContentfulQuery';
import { BookmarkToast } from '../../BookmarkToast/BookmarkToast';
import { Toast } from '../../Toast/Toast';
import { spsPlaygroundSectionCss } from './Sps2023Playground.styles';
import type { SpsPlaygroundData } from './Sps2023PlaygroundQuery';
import { spsPlaygroundQuery } from './Sps2023PlaygroundQuery';

/**
 * Component for testing and development during SPS.
 *
 * URL: http://localhost:3000/dev-playground
 */
export const SpsPlayground: FC<{ id: string }> = props => {
  const [poll, setPoll] = useState(true);
  const { data } = useContentfulQuery<SpsPlaygroundData, ContentfulIdVariable>(spsPlaygroundQuery, {
    variables: { id: props.id },
    pollInterval: poll ? 1000 : 0,
    fetchPolicy: 'no-cache',
  });

  const initialPolledText = useRef<string>();
  const [showTestToast, setShowTestToast] = useState<boolean>(false);
  const [showBookmarkToast, setShowBookmarkToast] = useState<boolean>(false);

  if (!data) return null;

  if (!initialPolledText.current) {
    initialPolledText.current = data.spsPlayground.polledText;
  }

  const polledText = data.spsPlayground.polledText;
  const publishedAt = data.spsPlayground.sys.publishedAt;

  return (
    <>
      <IntoHeaderPortal>🛝</IntoHeaderPortal>

      <section className={spsPlaygroundSectionCss}>
        <h1>Polling playground</h1>
        <h3>Original Title:</h3>
        <h4 data-testid="original-title" data-value={initialPolledText.current}>
          {initialPolledText.current}
        </h4>
        <br />
        <br />
        <h3>Polling Title:</h3>
        <h4 data-testid="polling-title" data-value={polledText}>
          {polledText}
        </h4>
        <h5 data-testid="published-at">{publishedAt}</h5>
        <br />
        <button onClick={() => setPoll(!poll)}>{poll ? 'Stop' : 'Start'} Poll</button>
      </section>

      <section className={spsPlaygroundSectionCss}>
        <h1>Toast playground</h1>
        <Button onClick={() => setShowTestToast(!showTestToast)}>
          {showTestToast ? 'Hide' : 'Show'} plain toast
        </Button>
        <Toast open={showTestToast} autoCloseTimeMs={25000} onClose={() => setShowTestToast(false)}>
          Hi mom
        </Toast>

        <Button onClick={() => setShowBookmarkToast(!showBookmarkToast)}>
          {showBookmarkToast ? 'Hide' : 'Show'} bookmark toast
        </Button>

        <BookmarkToast
          open={showBookmarkToast}
          analyticsId="Test Bookmark"
          onBookmark={() => {
            console.info('Triggered bookmark');
            setShowBookmarkToast(false);
          }}
          autoCloseTimeMs={5000}
          onClose={() => setShowBookmarkToast(false)}
        >
          {' '}
          ML Environment matching helps developers build photorealistic AR experiences in Lens
          Studio
        </BookmarkToast>
      </section>
    </>
  );
};
