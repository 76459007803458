import { lazyComponent } from '@snapchat/mw-common/client';
import { type FC, useState } from 'react';

import { SuspenseWrapper } from '../SuspenseWrapper';
import {
  eventPlayerWrapperCss,
  unloadedEventPlayerPlayButtonCss,
  unloadedEventPlayerPosterCss,
} from './EventPlayer.styles';
import { EventPlayerLoadingSpinner } from './EventPlayerLoadingSpinner';
import type { EventPlayerProps } from './LazyEventPlayer';

const LazyEventPlayer = lazyComponent(async () =>
  import('./LazyEventPlayer').then(module => ({ default: module.LazyEventPlayer }))
);

/** Wrapper which Lazy loads the EventPlayer component */
export const EventPlayer: FC<EventPlayerProps> = props => {
  const [hasPressedPlay, setHasPressedPlay] = useState(false);

  return (
    <div
      className={eventPlayerWrapperCss}
      onClick={() => setHasPressedPlay(true)}
      data-testid="event-player-wrapper"
    >
      {hasPressedPlay ? (
        <SuspenseWrapper fallbackElement={<EventPlayerLoadingSpinner />}>
          <LazyEventPlayer {...props} />
        </SuspenseWrapper>
      ) : (
        <>
          <img className={unloadedEventPlayerPosterCss} src={props.media.poster} />
          <img className={unloadedEventPlayerPlayButtonCss} src={'/play.svg'} />
        </>
      )}
    </div>
  );
};
