import type { FC } from 'react';

import { Button } from '../../../../../components/Button/Button';
import type { ContentfulIdVariable } from '../../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../../hooks/useContentfulQuery';
import { renderRichTextWithEmbeddings } from '../../../../../utils/renderText/renderRichText';
import { gridContainerCss, sps2024HomeBlockCss, topAlignOnDesktopCss } from './Sps2024Home.style';
import type { SpsHome2024Props } from './Sps2024HomeQuery';
import { spsHome2024Query } from './Sps2024HomeQuery';

/**
 * Homepage block for SPS 2024. Mocks:
 * https://www.figma.com/file/ys7GLHs9WHil6glFshP5D5/SPS-2023?node-id=353%3A12156&t=D8kZQ9m9MD9do8Hx-0
 */
export const Sps2024Home: FC<{ id: string }> = props => {
  const { data } = useContentfulQuery<SpsHome2024Props, ContentfulIdVariable>(spsHome2024Query, {
    variables: { id: props.id },
  });

  if (!data) return null;

  const { blurbOne, blurbOneButton, blurbTwo, blurbTwoButton } = data.spsHome2024;

  return (
    <section className={sps2024HomeBlockCss}>
      <article className={gridContainerCss}>
        <img src="/sps-static-logo.png" />
        <section>
          <section>{renderRichTextWithEmbeddings(blurbOne)}</section>
          {blurbOneButton && <Button {...blurbOneButton} />}
        </section>

        <img className={topAlignOnDesktopCss} src="/lens-fest-static-logo.png" />
        <section className={topAlignOnDesktopCss}>
          <section>{renderRichTextWithEmbeddings(blurbTwo)}</section>
          {blurbTwoButton && <Button {...blurbTwoButton} />}
        </section>
      </article>
    </section>
  );
};
