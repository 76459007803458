import { Button, ButtonType } from '@snapchat/snap-design-system-marketing';
import type { CSSProperties, FC } from 'react';

import { Anchor } from '../../../../../components/Anchor';
import { Block } from '../../../../../components/Block/Block';
import { Feature, useFeatureFlags } from '../../../../../components/FeatureFlags';
import { Form } from '../../../../../components/Form/Form';
import { useConsumerContext } from '../../../../../components/useConsumerContext';
import { renderRichTextWithEmbeddings } from '../../../../../utils/renderText/renderRichText';
import { SpsRegistrationStatus } from '../../../types';
import { Sps2024Animation } from '../Sps2024Animation';
import {
  regAddCalendarCss,
  registrationResultAnimationCss,
  registrationResultContainerCss,
  spsLogoCharCountCssVar,
  topPaddingCss,
  usernameFormTextClassName,
} from './sps2024Registration.styled';
import type { ConfirmationDataProps } from './Sps2024RegistrationQuery';

interface RegistrationResultProps extends ConfirmationDataProps {
  animationTextLines: string[];
  isReturningUser?: boolean;
  firstName?: string;
  registrationStatus?: SpsRegistrationStatus;
  onStartOver?: () => void;
  showUsernameForm: boolean;
}

// Helpful Tool to generate these : http://icalgen.yc.sg/, https://ical.marudot.com/
const irlGoogleEventLink =
  'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240425T153000Z%2F20240426T000000Z&details=Please%20join%20us%20in%20person%20for%20our%20sixth%20annual%20Snap%20Partner%20Summit%20on%20Thursday%2C%20April%2025%2C%202024.%0A%0ABreakfast%20will%20be%20served%20from%208%3A30%20AM%20PDT%20onwards%2C%20and%20the%20event%20officially%20begins%20at%209%3A00%20AM%2C%20featuring%20the%20keynote%20at%2010%3A00%20AM.%0A%0AWe%E2%80%99re%20excited%20to%20share%20more%20about%20the%20partnerships%2C%20content%2C%20and%20technologies%20that%20strengthen%20our%20community%E2%80%99s%20relationships%20with%20their%20friends%2C%20family%2C%20and%20world%20around%20them%20every%20day.&location=The%20Barker%20Hangar%203021%20Airport%20Ave%20Santa%20Monica%2C%20CA%209040&text=Snap%20Partner%20Summit%202024';
const virtualGoogleEventLink =
  'https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20240917T170000Z%2F20240917T183000Z&details=Please%20join%20us%20for%20our%20sixth%20annual%20Snap%20Partner%20Summit%20on%20Tuesday%2C%20September%2017%2C%202024.%20We%E2%80%99re%20excited%20to%20show%20you%20our%20latest%20innovations%20and%20share%20more%20about%20the%20partnerships%2C%20content%2C%20and%20technologies%20that%20strengthen%20our%20community%E2%80%99s%20relationships%20with%20their%20friends%2C%20family%2C%20and%20world%20around%20them%20every%20day.%20Join%20at%20snappartnersummit.com.&location=https%3A%2F%2Fwww.snappartnersummit.com&text=Snap%20Partner%20Summit%202024';

const calendarData = (attendanceValue?: SpsRegistrationStatus) => {
  switch (attendanceValue) {
    case SpsRegistrationStatus.IrlYes:
      return {
        googleEventLink: irlGoogleEventLink,
        icsUrl: '/sps-irl-2024.ics',
      };
    case SpsRegistrationStatus.Virtual:
      return {
        googleEventLink: virtualGoogleEventLink,
        icsUrl: '/sps-virtual-2024.ics',
      };
    default:
      return { googleEventLink: '', icsUrl: '' };
  }
};

export const RegistrationResult: FC<RegistrationResultProps> = ({
  firstName,
  title,
  animationTextLines,
  primaryBlurb,
  middleBlurb,
  secondaryBlurb,
  showSaveTheDate,
  showStartOverButton,
  buttonText,
  registrationStatus,
  isReturningUser,
  onStartOver,
  belowTheFoldCollection,
  usernameForm,
  usernameFormBlurb,
  showUsernameForm,
}) => {
  const features = useFeatureFlags();
  const { getUrlParams } = useConsumerContext();

  const getRegistrationToken = () => getUrlParams?.().registration_token;

  // Only render stream link to virtual attendees when feature flag is enabled
  const showGoToStreamButton =
    features[Feature.SPS_SHOW_GO_TO_STREAM_BUTTON] === 'true' &&
    registrationStatus === SpsRegistrationStatus.Virtual;

  const { googleEventLink, icsUrl } = calendarData(registrationStatus);

  const longestLineLength = animationTextLines.reduce((val, current) => {
    return Math.max(val, current.length);
  }, 0);

  if (isReturningUser) {
    title = `Welcome back, ${firstName}!`;
  }

  return (
    <>
      <div
        data-testid="sps-registration-result"
        className={registrationResultContainerCss}
        style={{ [spsLogoCharCountCssVar]: longestLineLength } as CSSProperties}
      >
        <Sps2024Animation
          className={registrationResultAnimationCss}
          textLines={animationTextLines}
        />
        <h1 className={!animationTextLines[-1] ? topPaddingCss : ''}>{title}</h1>
        {primaryBlurb && (
          <div className="primary-text">{renderRichTextWithEmbeddings(primaryBlurb)}</div>
        )}
        {registrationStatus && registrationStatus !== SpsRegistrationStatus.IrlNo && (
          <div className="event-info">
            {middleBlurb && <div>{renderRichTextWithEmbeddings(middleBlurb)}</div>}
            {showGoToStreamButton && (
              <Button type={ButtonType.Primary} link="/">
                Watch the Replay
              </Button>
            )}
            {secondaryBlurb && (
              <div className="address-section">
                <br />
                <div className="event-info-text">
                  {renderRichTextWithEmbeddings(secondaryBlurb)}
                </div>
              </div>
            )}
          </div>
        )}
        {showSaveTheDate && (
          <div className="save-the-date">
            <h1>Save the date</h1>
            <ul className={regAddCalendarCss}>
              <li>
                <Anchor href={googleEventLink}>
                  <img src="/gcal.png" />
                </Anchor>
              </li>
              <li>
                <Anchor href={icsUrl}>
                  <img src="/ical.png" />
                </Anchor>
              </li>
              <li>
                <Anchor href={icsUrl}>
                  <img src="/outlook.png" />
                </Anchor>
              </li>
            </ul>
          </div>
        )}
        {(showStartOverButton || !registrationStatus) && (
          <div data-testid="registration-result-action">
            {showStartOverButton && onStartOver && (
              <Button type={ButtonType.Secondary} onClick={onStartOver}>
                {buttonText}
              </Button>
            )}
            {!registrationStatus && (
              /* For an invalid status show return to homepage button */
              <Button type={ButtonType.Primary} link="/">
                {buttonText}
              </Button>
            )}
          </div>
        )}
        {showUsernameForm && usernameForm && (
          <>
            {usernameFormBlurb && (
              <section className={usernameFormTextClassName}>
                {renderRichTextWithEmbeddings(usernameFormBlurb)}
              </section>
            )}
            <Form
              {...usernameForm}
              extraParams={{ registration_token: getRegistrationToken() ?? '' }}
            />
          </>
        )}
      </div>
      {belowTheFoldCollection?.items.map(props => {
        const { sys, ...blockProps } = props;
        return (
          <Block
            sys={sys}
            key={sys.id}
            {...blockProps}
            backgroundColor={blockProps.backgroundColor}
          />
        );
      })}
    </>
  );
};
