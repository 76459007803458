import { gql } from '@apollo/client';

import type { ContentfulTypedSysProps } from '../../../../types/contentful';

export const bookmarkV2Fragment = gql`
  fragment BookmarkV2All on BookmarkV2 {
    sys {
      id
      publishedAt
      firstPublishedAt
    }
    title
    body
    analyticsId
  }
`;

export interface BookmarkV2Props extends ContentfulTypedSysProps<'BookmarkV2'> {
  sys: {
    id: string;
    publishedAt: Date;
    firstPublishedAt: Date;
  };
  title: string;
  body: string;
  analyticsId: string;
}

export const bookmarkV2Query = gql`
  query BookmarkV2Query($id: String!, $preview: Boolean!, $locale: String!) {
    bookmarkV2(id: $id, preview: $preview, locale: $locale) {
      ...BookmarkV2All
    }
  }
  ${bookmarkV2Fragment}
`;

export interface BookmarkV2DataProps extends ContentfulTypedSysProps<'BookmarkV2'> {
  bookmarkV2?: BookmarkV2Props;
}
