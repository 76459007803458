import type { Socket } from 'socket.io-client';
import { io as createSocket } from 'socket.io-client';

import { logError } from '../../../../helpers/logging';
import { handleSecproxySiteError } from '../../../../helpers/sockets/handleSecproxySiteError';
import { brsUrl } from './constants';

export interface ReactionSocketConfig {
  /** Snap access token. Needed to send bitmoji reactions. */
  accessToken: string;
  /** Handler for when the snap access token is expired or invalid. */
  onExpiredCredentials: () => void;
}

/** Create a socket to bitmoji reaction service to send reactions. */
export const getBitmojiReactionSocket = (
  socketConfig: ReactionSocketConfig
): Socket | undefined => {
  const { accessToken, onExpiredCredentials } = socketConfig;

  if (!accessToken) return undefined;

  const socket = createSocket(brsUrl as string, {
    path: '/bitmoji/socket',
    upgrade: false,
    transports: ['websocket'], // force websockets
    withCredentials: true,
    auth: {
      token: accessToken,
    },
  });

  // TODO add more analytics to these similar to useBroadcast
  socket.on('connect_error', (error: Error) => {
    logError({
      component: 'getBitmojiReactionSocket',
      error,
      // sometimes the error message is unhelpful so getting full json helps in troubleshooting.
      context: { errorJson: JSON.stringify(error) },
    });

    if (error.message.includes('access token is not valid')) {
      onExpiredCredentials();
    }

    handleSecproxySiteError(error, brsUrl);
  });

  socket.on('connect', () => {
    console.info(`Connected to reaction service.`);
  }); // done on both connect + reconnect

  return socket;
};
