import type { FC } from 'react';

import type { ContentfulIdVariable } from '../../../../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../../../../hooks/useContentfulQuery';
import { Sps2023Registration } from './Sps2023Registration';
import type { Sps2023RegistrationQueryData } from './Sps2023RegistrationQuery';
import { sps2023RegistrationQuery } from './Sps2023RegistrationQuery';

export const Sps2023RegistrationShallow: FC<{ id: string }> = props => {
  const id = props.id;

  const { data } = useContentfulQuery<Sps2023RegistrationQueryData, ContentfulIdVariable>(
    sps2023RegistrationQuery,
    {
      variables: {
        id,
      },
    }
  );

  if (!data) {
    return null;
  }

  return <Sps2023Registration {...data.sps2023Registration} />;
};
