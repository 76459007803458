import { Config } from '../../config';

const openedTabs = new Set<string>();

// Only to be used by dev and staging, due to secproxy needing authorization. We open the url to get the cookies.
export const handleSecproxySiteError = (error: Error, url: string): void => {
  // skip if prod, or is not a websocket error or we already opened the tab
  if (Config.isDeploymentTypeProd || error.message !== 'websocket error' || openedTabs.has(url)) {
    return;
  }

  const newTab = window.open(url, '_blank');

  if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
    alert(`Cannot open new tab to get secproxy cookies. Please open ${url} manually.`);
  }

  openedTabs.add(url);
};
