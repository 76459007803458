import { css, keyframes } from '@emotion/css';
import {
  mobileMediaQuery,
  nonMobileMediaQuery,
  smallMobileMediaQuery,
  Spacing,
} from '@snapchat/snap-design-system-marketing';

const captionOverlaySelector = '.amp-overlays div.amp-captioning.amp-overlay';

const showVolumeBarAnimation = keyframes`
  0% {
    visibility: hidden;
  }
  0.1% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
`;

const hideVolumeBarAnimation = keyframes`
  0% {
    visibility: visible;
    opacity: 1;
  }
  99.9% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
`;

export const ampPlayerCss = css`
  cursor: none;

  #amp {
    border-radius: 10px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    /* Fix for the video to have a border radius in Safari. */
    video {
      border-radius: 10px;
    }

    /** While title should be passed to amp config for analytics purposes,
    we don't actually want it to be visible on the player */
    .amp-title {
      display: none;
    }

    /**
     * Disables stretching the video height to match the width
     * enforcing a video object-fit of "contain" rather than "cover"
     */
    &.amp-full-screen {
      video {
        height: 100%;
      }

      /* Extend existing flex style to ensure captions are vertically aligned */
      .amp-overlays.amp-layer {
        align-items: center;
        justify-content: center;
      }

      .amp-captioning.amp-overlay {
        /* Specify the lesser of either Width OR Height to constrain the overlay to the video element size.
           and calculate the other dimension value based on expected video element aspect ratio.
           IMPORTANT NOTE: this will break if a video does not use these dimensions */
        @media (orientation: portrait) {
          width: 100vw;
          height: calc(100vw * (9 / 16));
        }

        @media (orientation: landscape) {
          /* fallback for browsers that do not support dvh */
          height: 100vh;
          width: calc(100vh * (16 / 9));
          height: 100dvh;
          width: calc(100dvh * (16 / 9));
        }

        /* Override the position: absolute styles to recreate the bottom style for spacing */
        position: relative;
        padding-bottom: 1.2em;

        .amp-caption-text {
          position: relative;
        }
      }
    }

    .amp-layer {
      display: flex;
    }

    .amp-panel {
      ${mobileMediaQuery} {
        max-height: calc(90% - 2.86em);
      }
    }

    .amp-caption {
      text-align: left;
      padding: 10px 20px;

      ${mobileMediaQuery} {
        font-size: 8px;
      }

      p {
        /* TODO: drop use of !important */
        font-family: Arial, Helvetica, sans-serif !important;
        font-variant: normal !important;
        font-size: 1.5em !important;
        line-height: 1.5em !important;
        text-shadow: none !important;
        color: white !important;
        background: none !important;
      }
    }
  }

  /*
   * Override to not let the font get bigger when in mobile view.
   * Without this the layout gets messed up because the padding
   * is computed in ems.
   */
  && .amp-ui {
    font: normal normal normal 14px/1 Arial;
  }

  /* By default, we hide the hints. */
  .amp-hint-component {
    opacity: 0;
  }

  .amp-controls {
    bottom: -2.86em !important;
    transition: opacity 0.2s ease;

    /* By default, we hide the controls */
    opacity: 0;

    ${mobileMediaQuery} {
      font-size: 14px;
    }

    [data-rh='Share'],
    [data-rh='Closed Captioning'],
    [data-rh='Picture in Picture'] {
      display: none;
    }
  }

  .amp-jump-back,
  .amp-jump-forward {
    display: none !important;
  }

  .amp-slider {
    display: none;
  }

  .amp-media-element {
    height: auto;
    width: calc(100% + 1px);
    top: 50%;
    transform: translateY(-50%);
  }

  .amp-muted .amp-unmute-layer {
    display: none;
  }

  .amp-paused .amp-pause-overlay.amp-icon,
  .amp-playing .amp-pause-overlay.amp-icon {
    display: block;
    opacity: 0;
    pointer-events: none;
  }

  .amp-control {
    background: rgba(0, 0, 0, 0.5);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    right: ${Spacing.SM}px;
  }

  .amp-pause-overlay,
  .amp-control {
    &:before {
      background-position: center;
      background-repeat: no-repeat;
      content: '' !important;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .amp-pause-overlay {
    &:before {
      background-size: 100%;
      background-position: center;
      background-image: url(/play.svg);
    }
  }

  .amp-ready .amp-playpause,
  .amp-ended .amp-playpause {
    display: none;
  }

  .amp-playpause {
    left: 12px;
    bottom: 55px;

    &:before {
      background-size: 24px 24px;
      background-position: center;
    }

    ${mobileMediaQuery} {
      display: none;
    }
  }

  .amp-playing {
    &.amp-vod {
      .amp-playpause {
        &:before {
          background-image: url(/pause.svg);
        }
      }

      .amp-pause-overlay {
        &:before {
          background-image: url(/pause.svg);
        }
      }
    }

    &.amp-live {
      .amp-playpause {
        &:before {
          background-image: url(/stop.svg);
        }
      }

      .amp-pause-overlay {
        &:before {
          background-image: url(/stop.svg);
        }
      }
    }
  }

  .amp-playpause {
    &:before {
      background-image: url(/play.svg);
    }
  }

  .amp-cc-active {
    .amp-settings {
      border: 1px solid #fffc00;
    }
  }

  .amp-settings {
    bottom: 145px;
    border: 1px solid transparent;
    transition: border 0.2s ease-out;

    &.amp-menu {
      display: none;
    }

    .show-captions & {
      border: 1px solid #fffc00;
    }

    &:before {
      background-image: url(/closed-captions.svg);
      background-size: 18px 17px;
    }
  }

  .amp-mute,
  .amp-custom-volume {
    bottom: 100px;
    border-radius: 20px;
    cursor: default;
  }

  .amp-html5 + & {
    .amp-custom-volume {
      input {
        animation-duration: 0.2s;
      }
    }
  }

  .amp-custom-volume {
    opacity: 0;

    &:before {
      background-image: none;
    }

    &.amp-muted {
      &:before {
        background-image: none;
      }
    }

    input {
      animation-name: ${hideVolumeBarAnimation};
      animation-duration: 0s;
      animation-fill-mode: forwards;
    }

    &:hover {
      height: 125px;
      z-index: 0;

      input {
        animation-name: ${showVolumeBarAnimation};
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
      }
    }
  }

  button.amp-mute {
    z-index: 1;
    background: none;
    border-radius: 20px;
    pointer-events: none;

    &:before {
      background-image: url(/sound-on.svg);
      background-size: 17px 17px;
      bottom: 8px;
      top: auto;
      background-position: bottom center;
    }

    &:after {
      content: '' !important;
    }

    &.amp-muted {
      &:before {
        background-image: url(/sound-off.svg);
        background-size: 20px 17px;
      }
    }
  }

  .amp-fullscreen {
    bottom: 55px;

    &:before {
      background-image: url(/full-screen-on.png);
      background-size: 28px 29px;
      background-position: 2px 3px;
    }

    &[data-rh='Exit Fullscreen Mode'] {
      &:before {
        background-image: url(/full-screen-off.svg);
        background-size: 29px 29px;
      }
    }
  }

  .amp-icon {
    opacity: 0.8;
    transition: all 0.2s ease-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.75);
      opacity: 1;
    }
  }

  .amp-error {
    .amp-component {
      pointer-events: none;
    }
  }

  .amp-hint-container {
    ${mobileMediaQuery} {
      display: none;
    }
  }

  .amp-time-display {
    display: none;
  }

  ${captionOverlaySelector} {
    bottom: 1.2em !important;
  }

  .amp-captioning-settings {
    background: white;
    color: black;
    right: 45px;
    bottom: 84px;
    border-radius: 10px;
    width: 250px;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 1px 1px 2px 2px rgb(0, 0, 0, 25%);

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }

    .settings-close {
      text-align: right;
    }

    .close-button {
      content: 'Close';
      background: black;
      border-radius: 20px;
      padding: 8px 20px;
      color: white;
      font-weight: 600;
      margin-top: 5px;
      display: inline-block;
      cursor: pointer;
    }

    ${mobileMediaQuery} {
      line-height: 12px;
      bottom: 40px;
      width: 150px;
      max-width: 50%;
    }

    .amp-captioning-home {
      > div:first-child,
      > div:nth-child(2) {
        display: none;
      }

      .amp-menu-item {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        &:hover {
          background-color: white;
        }

        .amp-selected {
          background: #00a881;
          border-radius: 50%;
          padding: 0;

          &:before {
            font-size: 10px;
            bottom: 1px;
            position: relative;
            color: white;
          }
        }

        > span {
          flex: 1;
          padding-left: 5px;
          opacity: 0.6;
          transition: opacity 0.2s ease-out;

          &:hover {
            opacity: 1;
          }
        }

        > .amp-selected + span {
          opacity: 1;
        }
      }
    }
  }

  ${mobileMediaQuery} {
    .amp-live {
      .amp-asl {
        right: 130px;
        bottom: 55px;
      }

      .amp-settings {
        right: 90px;
        bottom: 55px;
      }

      .amp-mute,
      .amp-custom-volume {
        right: 50px;
        bottom: 55px;
      }
    }
  }

  ${smallMobileMediaQuery} {
    .amp-vod {
      .amp-asl {
        bottom: 170px;
        right: 12px;
      }

      .amp-settings {
        bottom: 130px;
        right: 12px;
      }

      .amp-mute,
      .amp-custom-volume {
        bottom: 90px;
        right: 12px;
      }

      .amp-fullscreen {
        bottom: 50px;
        right: 12px;
      }
    }
  }
`;

export const aslButtonCss = css`
  position: absolute;
  bottom: 192px;
  right: 18px;
  z-index: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:before {
    background-image: url(/asl.svg);
    background-size: 24px 24px;
  }
`;

export const videoPlayerShowControlsCss = css`
  cursor: auto;

  .amp-hint-component {
    opacity: 1;
  }

  .amp-controls.amp-controls {
    opacity: 1;
  }

  .amp-vod .amp-slider {
    opacity: 1;
    display: block;
    bottom: 5px;
    width: calc(90% - 140px);
    background: none;
    right: 50%;
    transform: translateX(50%);

    .amp-track,
    .amp-value {
      border-radius: 5px;
    }

    .amp-range {
      display: none;
    }

    &:before {
      border-radius: 5px;
    }
  }

  ${mobileMediaQuery} {
    .amp-playing .amp-pause-overlay.amp-icon,
    .amp-paused .amp-pause-overlay.amp-icon {
      transition: opacity 0.2s ease-out;
      opacity: 0.8;
      pointer-events: all;
    }
  }

  /* For VODs, bump captions up if controls are visible so that
  progress bar doesn't cover up the captions */
  .amp-vod ${captionOverlaySelector} {
    bottom: 3.6em !important;
  }

  ${smallMobileMediaQuery} {
    .amp-vod .amp-slider {
      bottom: 0px;
    }
  }
`;

export const ampHideCcCss = css`
  [data-rh='Closed Captions'] {
    display: none;
  }
`;

export const ampHideAslCss = css`
  .amp-asl {
    display: none;
  }
`;

export const ampAslActiveCss = css`
  .amp-asl {
    border: 1px solid #fffc00;
  }
`;

export const firefoxVideoPlayerCss = css`
  .amp-asl {
    right: 130px;
    bottom: 55px;
  }

  .amp-settings {
    right: 90px;
    bottom: 55px;
  }

  .amp-mute,
  .amp-custom-volume {
    right: 50px;
    bottom: 55px;
  }

  .amp-custom-volume-slider {
    bottom: 65px;
  }

  ${nonMobileMediaQuery} {
    .amp-vod .amp-slider {
      width: calc(100% - 280px);
      right: 55%;
    }
  }

  ${mobileMediaQuery} {
    .amp-vod {
      .amp-controls.amp-bar {
        height: 100%;
        background-color: transparent;
        pointer-events: none;
      }

      .amp-asl {
        right: 90px;
        top: -30px;
      }

      .amp-settings {
        right: 50px;
        top: -30px;
      }

      .amp-mute,
      .amp-custom-volume {
        right: 10px;
        top: -30px;
      }

      .amp-custom-volume-slider {
        bottom: 45px;
      }

      .amp-custom-mute {
        top: 0;
      }
    }
  }
`;
