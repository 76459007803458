import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  m,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const spsLogoCharCountCssVar = '--sps-logo-char-count';
export const usernameFormTextClassName = 'username-form-text';

// Registration state styles

export const formOverrideCss = css`
  form {
    align-items: stretch;
    justify-content: flex-start;
    text-align: start;
    max-width: 510px;
    margin: unset;

    /* default text row items smaller than normal */
    p {
      font-size: 16px;
    }
    /* smaller font for checkbox text */
    label[for='email_updates'] > * {
      font-size: 14px;
    }
  }

  form > :nth-last-child(2) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const registrationFormCss = css`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;

  ${largeDesktopMediaQuery} {
    padding-left: 1vw;
  }

  .closed-text {
    line-height: 28px;
  }

  h5 {
    padding-bottom: 20px;
  }

  .error {
    color: #e93939;
  }

  /* override form alignment and other styling */
  ${formOverrideCss}
`;

export const registrationEventCss = css`
  min-height: 70px;
  line-height: 50px;

  p {
    font-size: 20px;
  }

  ${nonMobileMediaQuery} {
    text-align: center;
  }

  /* To render above the logo animation background */
  z-index: 1;
`;

export const registrationEventContainerCss = css`
  max-height: calc(100vh - var(--total-header-height));
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-evenly;

  max-width: 600px;

  ${mobileMediaQuery} {
    padding-left: unset;
  }
`;

export const animationOverrideCss = css`
  ${nonMobileMediaQuery} {
    /* To position animation closer it closer to the top */
    top: -136px;
  }
  top: -51px;
`;

export const registrationContainerCss = css`
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  /* make full screen when split is side by side */
  ${nonMobileMediaQuery} {
    height: 100vh;
    height: 100dvh;
    /* Use a negative margin so the container will overlap with the transparent header */
    margin-top: calc(var(--total-header-height) * -1);
  }

  /* Hides the scrollbar in the middle of the split block panel.
   * Hack so we can avoid modifying SDS-M on a strict timeline. */
  &&& > * > * {
    overflow: hidden;
    padding: 32px;
  }
`;

// Registration result and welcome back styles

export const registrationResultContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; /* To center-align the text */

  > * {
    max-width: 634px;
  }

  ${mobileMediaQuery} {
    display: inline;

    justify-content: left;
    align-items: left;
    text-align: left;

    > * {
      padding: 32px;
      position: relative; /* to get zindex to work smh */
      max-width: unset;
    }
    overflow: hidden; /* to not let the animation cause the page to need horizontal scroll. */
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .primary-text {
    padding: 0 32px;
    font-size: 20px;
    line-height: 28px;
    ${mobileMediaQuery} {
      font-size: 16px;
      padding-top: 0;
    }

    a {
      font-size: 20px;
    }
  }

  .${usernameFormTextClassName} {
    padding-block: 0 ${m('--spacing-l')};
    text-align: center;

    ${mobileMediaQuery} {
      padding-block: 0;
      text-align: start;
    }
  }

  &&& {
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      padding-bottom: 24px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      padding-bottom: 8px;
    }
    ${mobileMediaQuery} {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .event-info {
    ${nonMobileMediaQuery} {
      padding-top: 48px;
    }

    a {
      font-size: 20px;
    }
  }

  .event-info-text {
    line-height: 26px;
  }

  .save-the-date {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  button {
    margin-bottom: 32px;
    ${nonMobileMediaQuery} {
      margin-bottom: 124px;
    }
  }

  /* Make everything that's not the animation render in front, so that the animation does not paint over it. */
  & > *:not(figure) {
    z-index: 1;
  }
`;

/**
 * Used to offset the logo alignment by an amount based on the character count of the longest line
 * (based on current text versions)
 *
 * NOTES: value is very fragile, dependent on animation file at time of writing and styling of the
 * registration page with % based logo alignment. must be recalculated if the animation file changes
 * (particularly font size, style, positioning). value calculated by:
 *
 * - Calculating the difference between longest and shortest text variations
 * - When its longest (12 char "REGISTERING,") the ideal is -49%
 * - When its shortest (7 char "PARTNER") the ideal is -37%
 * - 12% * (12-charcount)/7 - 49% is the equation to get them
 */
const characterOffsetPercentage = 12;
const baseOffset = 49;
const longestCharCount = 12;
const charDiff = 5;

export const registrationResultAnimationCss = css`
  width: 600px;
  margin-top: -65px;

  ${nonMobileMediaQuery} {
    /* Center the logo by calculating offset by longest line of text */
    .sps-animation-canvas {
      left: calc(
        ${characterOffsetPercentage}% * (${longestCharCount} - var(${spsLogoCharCountCssVar})) /
          ${charDiff} - ${baseOffset}%
      );
    }
    padding-bottom: 64px;
  }

  ${mobileMediaQuery} {
    margin-top: 16px;
    margin-left: 32px;
    width: calc(100vw - 64px * 2 * var(${spsLogoCharCountCssVar}) / ${longestCharCount});
  }
`;

export const regAddCalendarCss = css`
  list-style: none;
  margin-bottom: 30px;
  li {
    display: inline-block;
    img {
      width: 50px;
    }
  }
  li:not(:first-child) {
    margin-left: 44px;
  }
`;

export const topPaddingCss = css`
  margin-top: 32px;
`;
