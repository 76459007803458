import { cx } from '@emotion/css';
import { globalHeaderHeight, useWindowSize } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useEffect, useRef } from 'react';

import type { BitmojiProps } from '../BitmojiControls/types';
import type { BitmojiStreamItem } from '../BitmojiProvider';
import { BitmojiContext, invalidImgUrl } from '../BitmojiProvider';
import { bitmojiCanvasCss } from './bitmojiCanvas.styled';
import { bitmojiCanvasWidthPixels } from './constants';

const defaultCanvasHeight = 600;

export const BitmojiCanvas: FC<BitmojiProps> = ({ enableBitmoji = true, className }) => {
  const animationIdRef = useRef(0);

  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { bitmojiStreamRef, canvasRef } = useContext(BitmojiContext);

  const drawBitmoji = (item: BitmojiStreamItem) => {
    // Opacity is a function of how close to the top of the canvas
    // the bitmoji is (i.e. how close y coordinate is to 0).
    // Only start fading bitmoji in the top quarter of the canvas.
    const canvasHeight = canvasRef?.current?.height ?? defaultCanvasHeight;
    const opacity = item.y / (canvasHeight / 4);

    if (!item.ctx || item.image.src.endsWith(invalidImgUrl)) return;

    item.ctx.globalAlpha = Math.max(0, opacity);
    item.ctx.drawImage(item.image, item.x, item.y, item.width, item.height); // draw image at current position
  };

  const animate = () => {
    const canvas = canvasRef?.current;
    const bitmojiStream = bitmojiStreamRef?.current;

    if (!canvas || !bitmojiStream) return;

    const ctx = canvas?.getContext('2d') as CanvasRenderingContext2D;
    ctx?.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0); // clear canvas

    // Transform the coordinates of existing stream
    const newStream = bitmojiStream
      .map(({ ctx, image, x, y, width, height }) => {
        const newY: number = y - 2.5;
        return { ctx, image, x, y: newY, width, height };
      })
      .filter(({ y }) => y > 0);

    // Draw the bitmoji stream in new positions
    newStream?.forEach(bitmoji => drawBitmoji(bitmoji));

    // Update the bitmoji stream with new positinos
    bitmojiStreamRef.current = newStream;

    // Update canvas context and animation ref
    if (ctx) ctx.globalAlpha = 1;
    animationIdRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (canvasRef?.current) {
      animate();
    }

    // Need to cancel animation loop when we no longer have a canvas.
    return () => cancelAnimationFrame(animationIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!canvasRef?.current) return;

    canvasRef.current.height = windowHeight
      ? windowHeight - globalHeaderHeight
      : defaultCanvasHeight;
    canvasRef.current.width = bitmojiCanvasWidthPixels;
  }, [windowWidth, windowHeight, canvasRef]);

  if (!enableBitmoji) {
    return null;
  }

  return (
    <canvas
      id="bitmoji-reaction-canvas"
      className={cx(bitmojiCanvasCss, className)}
      ref={canvasRef}
    />
  );
};
