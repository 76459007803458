import { css } from '@emotion/css';

// TODO: Find a way to cleanly show bitmoji reactions in mobile
export const bitmojiCanvasCss = css`
  position: absolute;
  top: calc(50% + 47px);
  left: 0;
  transform: translate(-100%, -50%);
  opacity: 1;
  transition: all 0.2s ease-out;

  .hide-bitmoji & {
    opacity: 0;
  }
`;
