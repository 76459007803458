import { gql } from '@apollo/client';
import type { FormDataProps } from 'src/components/Form';

import type { BlockDataProps } from '../../../../../components/Block';
import { formFragments } from '../../../../../components/Form';
import type { ContentfulTypedSysProps } from '../../../../../types/contentful';
import type { Items } from '../../../../../types/Items';
import type { RichText } from '../../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../../utils/contentful/contentfulQuery';
import { confirmationFragment } from './confirmationFragment';

export interface ConfirmationDataProps extends ContentfulTypedSysProps<'Confirmation'> {
  title: string;
  primaryBlurb: RichText;
  middleBlurb: RichText;
  secondaryBlurb: RichText;
  showSaveTheDate: boolean;
  showStartOverButton: boolean;
  buttonText?: string;
  belowTheFoldCollection?: Items<BlockDataProps>;
  usernameForm?: FormDataProps;
  usernameFormBlurb?: RichText;
}

const sps2024RegistrationFragment = gql`
  fragment Sps2024RegistrationFragment on Sps2024Registration {
    blurb {
      json
    }
    formTitle
    allowlistErrorText
    formErrorText
    defaultAnimationTextLines
    personalizedAnimationTextLines
    noNameAnimationTextLines
    registrationForm {
      ...FormAll
    }
    irlConfirmation {
      ...ConfirmationAll2024
    }
    virtualConfirmation {
      ...ConfirmationAll2024
    }
    notAttendingConfirmation {
      ...ConfirmationAll2024
    }
    intermediateConfirmation {
      ...ConfirmationAll2024
    }
    errorPage {
      ...ConfirmationAll2024
    }
    requireEmailToken
    isClosed
    closedText {
      json
    }
    ...ContentfulSysId
  }
  ${formFragments.all}
  ${confirmationFragment}
  ${contentfulSysIdFragment}
`;

export interface Sps2024RegistrationProps extends ContentfulTypedSysProps<'Sps2024Registration'> {
  blurb: RichText;
  formTitle: string;
  formErrorText: string;
  allowlistErrorText: string;
  registrationForm: FormDataProps;
  irlConfirmation: ConfirmationDataProps;
  virtualConfirmation: ConfirmationDataProps;
  notAttendingConfirmation: ConfirmationDataProps;
  intermediateConfirmation: ConfirmationDataProps;
  errorPage: ConfirmationDataProps;
  defaultAnimationTextLines?: string[];
  personalizedAnimationTextLines?: string[];
  noNameAnimationTextLines?: string[];
  requireEmailToken: boolean;
  isClosed: boolean;
  closedText: RichText;
}

// =================================================================================================
// RegistrationBlock Query
// =================================================================================================

export const sps2024RegistrationQuery = gql`
  query Sps2024Registration($id: String!, $locale: String!, $preview: Boolean!) {
    sps2024Registration(id: $id, locale: $locale, preview: $preview) {
      ...Sps2024RegistrationFragment
    }
  }
  ${sps2024RegistrationFragment}
`;

export interface Sps2024RegistrationQueryData {
  sps2024Registration: Sps2024RegistrationProps;
}
