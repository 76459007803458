import type { GrapheneLoggingContext } from '@snapchat/logging';
import type { Request } from 'express';

import type { MarketingWebConfiguration } from '../../configTypes';
import type { GoogleEcommerceEvent, GoogleEventName } from '../../types/gtm';
import type { UserAgentHints } from '../../types/userAgentHints';
import type { BlizzardContext } from './blizzard/eventFormats/types';
import type { SubscribedEventType } from './eventListenerTypes';

// =================================================================================================
// Event Bus and Listener interfaces.
// =================================================================================================

export type LoggingConfig = Pick<
  MarketingWebConfiguration,
  | 'isClient'
  | 'isDeploymentTypeProd'
  | 'compilationMode'
  | 'deploymentType'
  | 'featureFlags'
  | 'isCompilationModeProd'
  | 'trackingSettings'
  | 'buildNumber'
  | 'domainName'
  | 'region'
  | 'isLocal'
  | 'isTest'
  | 'googleCloudProjectName'
  | 'site'
>;

export type LoggingCustomEvents =
  | FirstPageLoadEvent
  | PageLoadEvent
  | PhoneNumberFormEvent
  | EcommerceEvent
  | ExperimentImpressionEvent;

/** @deprecated Use the standard events from the logging package. */
export type LegacyAllEvents =
  | LoggingCustomEvents
  | WarningEvent
  | ErrorEvent
  | UserInteractionEvent
  | InternalEvent
  | InternalValueEvent
  | InternalTimingEvent;

export interface ExperimentContext {
  experimentId?: string;
  variantId?: string;
}

/** Where an event originated from. */
export enum EventOrigin {
  CLIENT = 'client',
  SERVER = 'server',
}

/**
 * App context - use this to pass in global state so we don't have to include in every logEvent
 * call.
 */
export interface LoggingContext extends GrapheneLoggingContext, ExperimentContext, BlizzardContext {
  // Global fields.
  // TODO: Move into env:
  region: string;
  buildNumber: string;
  eventOrigin: EventOrigin;

  // Fields from App Context
  // TODO: Move into app:
  locale?: string;
  hostname?: string;
  path: string;
  userCountry?: string;
  isRtl?: boolean;
  userAgentHints?: UserAgentHints;
  uaBrand: string; // Brand from user agent hints
  uaPlatform: string; // Platform from user agent hints.
  analyticsId: string;

  // Fields for server-side logging.
  // TODO: Move into GoogleCloudContext
  url?: URL; // Also used for appContext.
  request: Request;
  spanId: string;
  traceId: string;

  // Types for CSP Errors
  // TODO: Move into csp:
  blockedUri: string;
  sourceFile: string;
  violatedDirective: string;

  // Random component props
  // TODO: Move into react:
  name: string; // For rendering
  slug: string; // For slug rendering
  stack: string; // For errors (duplicate?)
  componentStack: string; // For errors
  cause: string; // For errors
  errorJson: string; // For errors
  errorMessage: string; // For errors
  errorCode: number; // For errors
  errorName: string; // For errors
  eventKey: string;
  requestMessage: string; // For GRPC
  node: string; // Rendering node

  // Contentful props
  // TODO: Move into contentful:
  type: string; // For page rendering
  contentType: string; // For cheerios rendering (duplicate?)
  typename: string; // For rendering (duplicate?)
  entryId: string; // For rendering
  sysId: string; // For rendering
  locations: string; // For contentful
  variables: string; // For query logs
  queryName: string; // For contentful
  queryPath: string; // For contentful
  requestUrl: string; // For contentful
  responseCode: string; // For rendering contentful
  responseStatus: string | number; // For contentful latency
  contentfulRequestStatus: string; // For contentful latency
  'x-contentful-region': string | null; // For contentful latency
  'x-cache': string | null; // For contentful latency
  'x-contentful-request-id': string | null; // For contentful latency
  'x-served-by': string | null; // for fastly debugging

  // SPS props
  // TODO: move into sps:
  monitor: string; // For SPS
  existingRegistration: string; // For SPS
  email: string; // For SPS
}

export type LoggingPermissions = {
  logging: boolean;
  marketing: boolean;
  performance: boolean;
};

// =================================================================================================
// Error Events
// =================================================================================================

export type ErrorEvent = {
  subscribedEventType: SubscribedEventType.ERROR;
  component: string;
  /** Action that caused error or other value to distringuish between errors of the same component */
  action?: string;
  error?: Error | unknown;
  message?: string;
  context?: Partial<LoggingContext>;
};

// =================================================================================================
// Warning Event
// =================================================================================================

export type WarningEvent = {
  subscribedEventType: SubscribedEventType.WARNING;
  /** One word grouping of the warning. */
  component: string;
  /** Human-readable text entry. */
  message?: string;
  /** Structured labels for the warning to provide context */
  context?: Partial<LoggingContext>;
};

// =================================================================================================
// User Interaction Events
// =================================================================================================

export interface UserInteractionEventData {
  /** @deprecated - Do not set this value. Let it default to 'analyticsEvent' */
  event?: GoogleEventName;
  /** Name of the component where the event was fired. */
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
}

export type UserInteractionEvent = UserInteractionEventData & {
  subscribedEventType: SubscribedEventType.USER_INTERACTION;
};

// =================================================================================================
// Internal Event
// =================================================================================================

export type InternalEvent = {
  subscribedEventType: SubscribedEventType.INTERNAL;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  /** Context for the event. Note that this does not get logged outside of the the console. */
  context?: Partial<LoggingContext>;
};

export type InternalValueEvent = {
  subscribedEventType: SubscribedEventType.INTERNAL_VALUE;
  eventVariable: string;
  eventValue: number;
  eventCategory: string;
  eventLabel?: string;
  /** Context for the event. Note that this does not get logged outside of the the console. */
  context?: Partial<LoggingContext>;
};

export type InternalTimingEvent = {
  subscribedEventType: SubscribedEventType.INTERNAL_TIMING;
  eventVariable: string;
  eventValue: number;
  eventCategory: string;
  eventLabel?: string;
  /** Context for the event. Note that this does not get logged outside of the the console. */
  context?: Partial<LoggingContext>;
};

// =================================================================================================
// Custom Event: Page Load
// =================================================================================================

export interface FirstPageLoadEvent {
  subscribedEventType: SubscribedEventType.FIRST_PAGE_LOAD;
  context?: Partial<LoggingContext>; // TODO: Remove. Unused.
}

export interface PageLoadEvent {
  subscribedEventType: SubscribedEventType.PAGE_LOAD;
  context?: Partial<LoggingContext>;
}

// =================================================================================================
// Custom Event: Phone Number Form
// =================================================================================================
export interface PhoneNumberFormEventData {
  event?: GoogleEventName;
  appStoreName?: string;
  countryCode?: string;
  elementRelativePagePosition?: string;
  context?: Partial<LoggingContext>;
}

export interface PhoneNumberFormEvent extends PhoneNumberFormEventData {
  subscribedEventType: SubscribedEventType.PHONE_NUMBER_EVENT;
  context?: Partial<LoggingContext>; // TODO: Remove. Unused.
}

// =================================================================================================
// Custom Event: Ecommerce Event
// =================================================================================================
/** @deprecated */
export interface EcommerceEvent extends GoogleEcommerceEvent {
  subscribedEventType: SubscribedEventType.ECOMMERCE;
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  context?: Partial<LoggingContext>; // TODO: Remove. Unused.
}

// =================================================================================================
// Custom Event: Experiment Impression Event
// =================================================================================================
export interface ExperimentImpressionEvent {
  subscribedEventType: SubscribedEventType.EXPERIMENT_IMPRESSION;
  experimentId?: string;
  variantId?: string;
  context?: Partial<LoggingContext>; // TODO: Remove. Unused.
}

// NOTE: Please avoid creating custom event types. All events can fall into the categories of
// Error, UserInteraction, Internal, InternalTiming.
