import { cx } from '@emotion/css';
import { BrowserFeaturesContext } from '@snapchat/snap-design-system-marketing';
import isEqual from 'lodash/isEqual';
import type { FC } from 'react';
import { memo, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import { EventPlayer } from '../../../../components/EventPlayer/EventPlayer';
import type { EventMedia, TimestampBehavior } from '../../../../components/EventPlayer/types';
import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import type { AkamaiMediaConfiguration, Player } from '../../../../types/akamai';
import { useBitmojiReplays } from '../../hooks/useBitmojiReplays/useBitmojiReplays';
import { BitmojiCanvas } from '../BitmojiCanvas/BitmojiCanvas';
import { BitmojiControls } from '../BitmojiControls/BitmojiControls';
import type { BitmojiProps } from '../BitmojiControls/types';
import { BitmojiContext } from '../BitmojiProvider';
import type { VideoControlsAction } from './AkamaiPlayer';
import { AkamaiPlayer } from './AkamaiPlayer';
import type { SharedControlsState } from './types';
import {
  hideOnMobileCss,
  videoAndControlsContainerCss,
  videoContainerCss,
  videoPositionCss,
} from './VideoFrame.styles';

export interface VideoFrameProps {
  media: AkamaiMediaConfiguration;
  aslMedia?: AkamaiMediaConfiguration;
  eventMedia: EventMedia;
  bitmojiProps: BitmojiProps;
  videoId?: string;
  analyticsId: string;
  /**
   * Controls whether to display reaction controls, allow video scrubbing, and how to connect to
   * bitmoji stream
   */
  isLiveEvent: boolean;
  /** How player reports back current timestamp of video. Impacts logging of Video Play events. */
  timestampBehavior: TimestampBehavior;
  /** Amount to offset video progress by. */
  progressOffset?: number;
  disableCaptions?: boolean;
  onReceiveBookmark?: (bookmarkId: string) => void;
}

const controlsStateReducer = (
  state: SharedControlsState,
  action: VideoControlsAction
): SharedControlsState => {
  switch (action.type) {
    case 'changeHoverState':
      return { ...state, areControlsHovered: action.value };
    case 'changeFullScreenState':
      return { ...state, isFullscreen: action.value };
    case 'changePlayingState':
      return { ...state, isPlaying: action.value };
    case 'changeLoadingState':
      return { ...state, isLoading: action.value };
    case 'toggleAslSelectedState':
      return { ...state, isAslSelected: !state.isAslSelected };
    default:
      return state;
  }
};

/** Composite UI of Bitmoji canvas, Akamai Player, and Login Kit / Bitmoji Interaction buttons */
const InternalVideoFrame: FC<VideoFrameProps> = ({
  media,
  aslMedia,
  eventMedia,
  bitmojiProps,
  isLiveEvent,
  timestampBehavior,
  progressOffset,
  disableCaptions,
  analyticsId,
  onReceiveBookmark,
}) => {
  const features = useFeatureFlags();
  const useCustomEventPlayer = features[Feature.SPS_USE_EVENT_PLAYER] === 'true';
  /* === state management =========================================================== */

  // Video player - shares play state w/ bitmoji stream
  const [player, setPlayer] = useState<Player>();
  // user has the ability to hide bitmoji UI elements
  const [hideBitmojiUI, setHideBitmojiUI] = useState(false);
  // shared state for dispaying controls - mostly video but overlaps w/ bitmoji stream
  // TODO: Remove in favor of "isPlaying" once AkamaiPlayer is removed
  const [showControls, controlsDispatch] = useReducer(controlsStateReducer, {
    areControlsHovered: false,
    isFullscreen: false,
    isLoading: false,
    isPlaying: false,
    isAslSelected: false,
  });

  const [isPlaying, setIsPlaying] = useState(false);

  const videoTimestamp = useRef<number | undefined>();

  const updateVideoTimestamp = useCallback(
    (timestamp: number) => {
      videoTimestamp.current = timestamp;
      // This may take time to be correct after pause?!
      // Need to work with Brandon
    },
    [videoTimestamp]
  );

  const { updateVideoPaused } = useContext(BitmojiContext);

  const onPlayStateChange = useCallback(
    (isPlaying: boolean) => {
      setIsPlaying(isPlaying);
      updateVideoPaused?.(!isPlaying);
    },
    [updateVideoPaused]
  );

  // TODO: Remove once Akamai player is removed
  useEffect(() => {
    updateVideoPaused?.(!showControls.isPlaying);
  }, [showControls.isPlaying, updateVideoPaused]);

  /* === custom hooks ================================================================ */

  // For VODs (i.e. isLiveEvent === false), we pass in undefined bitmoji props to prevent the
  // hook from firing. We don't want to be pulling reactions from the replay hook
  // for a live event
  const bitmojiReplayProps = !isLiveEvent ? bitmojiProps : undefined;
  useBitmojiReplays(videoTimestamp, bitmojiReplayProps);

  /* === render ===================================================================== */

  // TODO: refactor this.  We shouldn't need to pass this around to multiple elements & components...
  const bitmojiToggleClass = hideBitmojiUI ? 'hide-bitmoji' : 'show-bitmoji';

  const browserFeatures = useContext(BrowserFeaturesContext);
  const isMobile = browserFeatures.getLowEntropyHints().isMobile;

  return (
    //  Container div to align bitmoji controls with video container */}
    <div className={videoAndControlsContainerCss}>
      <div className={cx(videoContainerCss, bitmojiToggleClass)}>
        {/* We don't want any bitmoji interaction on mobile as it produces a bad/cluttered UX. Approach here is to hide
           the canvas & controls both when a mobile device is detected based on browser attributes (captured by isMobile())
           as well as for mobile-like screen sizes (covered by hideOnMobileCss class). The reason we cannot rely just on
           the hideOnMobileCss class is because that will still not trigger for most phones in landscape mode. */}
        {!isMobile && <BitmojiCanvas {...bitmojiProps} className={hideOnMobileCss} />}
        {useCustomEventPlayer ? (
          <EventPlayer
            media={eventMedia}
            isLiveEvent={isLiveEvent}
            timestampBehavior={timestampBehavior}
            progressOffset={progressOffset}
            onProgressUpdated={updateVideoTimestamp}
            onReceiveBookmark={onReceiveBookmark}
            onPlayStateChange={onPlayStateChange}
            analyticsId={analyticsId}
          />
        ) : (
          <div className={cx(videoPositionCss)}>
            <AkamaiPlayer
              media={media}
              aslMedia={aslMedia}
              player={player}
              isPlaying={showControls.isPlaying}
              isLiveEvent={isLiveEvent}
              showControls={showControls}
              timestampBehavior={timestampBehavior}
              progressOffset={progressOffset}
              disableCaptions={disableCaptions}
              setPlayer={setPlayer}
              controlsDispatch={controlsDispatch}
              onReceiveBookmark={onReceiveBookmark}
              onProgressUpdated={updateVideoTimestamp}
              analyticsId={analyticsId}
            />
          </div>
        )}
      </div>
      {isLiveEvent && !isMobile && bitmojiProps.enableBitmoji && (
        <div className={cx(hideOnMobileCss)}>
          <BitmojiControls
            hideBitmoji={hideBitmojiUI}
            hideBitmojiClass={bitmojiToggleClass}
            buttonsDisabled={useCustomEventPlayer ? !isPlaying : !showControls.isPlaying}
            setHideBitmoji={setHideBitmojiUI}
            videoTimestampRef={videoTimestamp}
            bitmojiProps={bitmojiProps}
            analyticsId={analyticsId}
          />
        </div>
      )}
    </div>
  );
};

export const VideoFrame = memo(InternalVideoFrame, (prev, next) => isEqual(prev, next));

VideoFrame.displayName = 'VideoFrame';
