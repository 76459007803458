import { cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import { getLocalStorageItem, removeLocalStorageItem } from '@snapchat/mw-common';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useCallback, useContext, useRef, useState } from 'react';

import type { EventMedia } from '../../../../components/EventPlayer/types';
import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { PageLayoutContext } from '../../../../context/PageLayoutContext';
import { logUserEvent, logWarning } from '../../../../helpers/logging/loggingInstance';
import { UserAction } from '../../../../types/events';
import { BookmarkToastShallow } from '../BookmarkToast/BookmarkToast';
import { interestsStorageKey } from '../Sps2024/Sps2024Registration/Sps2024Registration';
import { buildMediaConfiguration } from '../VideoFrame';
import type { VideoFrameProps } from '../VideoFrame/VideoFrame';
import { VideoFrame } from '../VideoFrame/VideoFrame';
import type { SpsEventProps } from './eventQuery';
import { darkModeCss, liveEventWrapperCss } from './LiveEvent.styled';

type Props = Pick<SpsEventProps, 'stream'>;

export const LiveEvent: FC<Props> = props => {
  const { stream } = props;

  const features = useFeatureFlags();
  const enableBitmoji = features[Feature.EXPERIENCE_ENABLE_BITMOJI] === 'true';
  const enableAsl = features[Feature.EXPERIENCE_ENABLE_ASL_STREAM] === 'true';
  const enableCaptions = features[Feature.EXPERIENCE_ENABLE_CAPTIONS] === 'true';
  const enableBookmarks = features[Feature.EXPERIENCE_ENABLE_BOOKMARKS] === 'true';
  const enableDarkModeForLive = features[Feature.EXPERIENCE_ENABLE_DARK_MODE_FOR_LIVE] === 'true';

  const { setHeaderBackgroundColorOverride } = useContext(PageLayoutContext);

  const seenBookmarksSetRef = useRef<Set<string>>(new Set([]));
  const [bookmarkId, setBookmarkId] = useState<string | null>(null);
  const [showBookmark, setShowBookmark] = useState(false);

  const onReceiveBookmark = useCallback(
    (bookmark: string) => {
      if (!enableBookmarks) {
        return;
      }

      // Ignore the bookmark if we've seen received it before. This shouldn't happen so log an error if so.
      if (seenBookmarksSetRef.current.has(bookmark)) {
        logWarning({
          component: 'LiveEvent',
          message: `Received duplicate bookmark id: ${bookmark}`,
        });

        return;
      }

      setBookmarkId(bookmark);
      setShowBookmark(true);
      seenBookmarksSetRef.current = new Set([...seenBookmarksSetRef.current, bookmark]);
    },
    [enableBookmarks]
  );

  const media = buildMediaConfiguration(
    stream.title ?? '',
    stream.streamUrl!,
    stream.captionsCollection,
    stream.posterImage.url,
    {
      temporalType: 'live',
    }
  );

  const aslMedia =
    enableAsl && stream.aslStreamUrl
      ? buildMediaConfiguration(
          stream.title ?? '',
          stream.aslStreamUrl,
          stream.captionsCollection,
          stream.posterImage.url,
          {
            temporalType: 'live',
          }
        )
      : undefined;

  const eventMedia: EventMedia = {
    src: stream.streamUrl!,
    aslSrc: stream.aslStreamUrl,
    poster: stream.posterImage.url,
    title: stream.title,
    textTracks: stream.captionsCollection?.items.map(caption => ({
      languageCode: caption.sourceLanguage,
      src: caption.sourceUrl!,
      kind: caption.kind ?? 'subtitles',
    })),
  };

  const videoFrameProps: VideoFrameProps = {
    videoId: stream.sys.id,
    media,
    aslMedia,
    eventMedia,
    bitmojiProps: {
      enableBitmoji,
      videoId: stream.sys.id,
    },
    analyticsId: stream.analyticsId,
    isLiveEvent: true,
    timestampBehavior: 'Use Manifest Timestamps',
    disableCaptions: !enableCaptions,
    onReceiveBookmark,
  };

  // Update header background and log user interests from registration
  useImperativeEffect(() => {
    enableDarkModeForLive && setHeaderBackgroundColorOverride?.(BackgroundColor.Black);

    const interests = (getLocalStorageItem(interestsStorageKey) ?? '').split(',');

    // fire separate a separate event for each expressed interest
    interests
      .filter(interest => interest !== '')
      .map(interest =>
        logUserEvent({
          eventAction: UserAction.Open,
          eventCategory: 'LiveEvent',
          eventLabel: `interest: ${interest}`,
        })
      );

    removeLocalStorageItem(interestsStorageKey);
  }, [enableDarkModeForLive, setHeaderBackgroundColorOverride]);

  return (
    <div className={cx(liveEventWrapperCss, { [darkModeCss]: enableDarkModeForLive })}>
      {enableBookmarks && bookmarkId && (
        <BookmarkToastShallow
          open={showBookmark}
          sys={{ id: bookmarkId }}
          onBookmark={() => {
            setShowBookmark(false);
          }}
          onClose={() => {
            setShowBookmark(false);
          }}
          autoCloseTimeMs={10e3}
          __typename="BookmarkV2"
        />
      )}
      <VideoFrame {...videoFrameProps} />
    </div>
  );
};
