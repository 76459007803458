import type { InputField } from '@snapchat/mw-contentful-schema';
import type { FormBody } from '@snapchat/snap-design-system-marketing';
import { BackgroundColor, BlockSplitPanel } from '@snapchat/snap-design-system-marketing';
import cloneDeep from 'lodash-es/cloneDeep';
import { type FC, useContext } from 'react';

import { AppContext } from '../../../../../AppContext';
import { ConsumerContext } from '../../../../../components/ConsumerContextProvider';
import type { FormDataProps } from '../../../../../components/Form';
import { getFormField } from '../../../../../components/Form';
import { Form } from '../../../../../components/Form/Form';
import type { RichText } from '../../../../../types/RichText';
import { renderRichText } from '../../../../../utils/renderText/renderRichText';
import { Sps2023Animation } from '../Sps2023Animation/Sps2023Animation';
import {
  registrationContainerCss,
  registrationEventContainerCss,
  registrationEventCss,
  registrationFormCss,
} from './sps2023Registration.styled';

interface RegistrationFormProps {
  blurb: RichText;
  title?: string;
  animationTextLines: string[];
  error?: string;
  form?: FormDataProps;
  spsRegistrationEmail?: string;
  isClosed?: boolean;
  closedText?: RichText;
  onFormSubmit?: (response: Response, formBody: FormBody) => void;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({
  blurb,
  title,
  animationTextLines,
  error,
  form,
  spsRegistrationEmail,
  isClosed,
  closedText,
  onFormSubmit,
}) => {
  const { getUrlParams } = useContext(ConsumerContext);
  const { userLocation } = useContext(AppContext);

  if (!form) {
    return null;
  }

  const editableForm = cloneDeep(form);

  const getRegistrationToken = () => getUrlParams?.().registration_token;

  // Override 'initialValue' property of the 'email' field to autofill
  // to email decoded from URL
  const emailField = getFormField(editableForm, 'email') as InputField;

  if (emailField && spsRegistrationEmail) {
    emailField.initialValue = spsRegistrationEmail;
    (emailField as Record<string, unknown>).readOnly = true;
  }

  // Override the default value of the Subscribe to Marketing field in the US only.
  const emailUpdatesField = getFormField(editableForm, 'email_updates') as InputField;
  const isInTheUs = userLocation.country === 'US';

  if (emailUpdatesField && isInTheUs) {
    emailUpdatesField.initialValue = 'true';
  }

  const leftContent = (
    <div data-testid="registration-left-side" className={registrationEventContainerCss}>
      <Sps2023Animation data-testid="registration-logo" textLines={animationTextLines} />
      <div data-testid="registration-information" className={registrationEventCss}>
        {renderRichText(blurb)}
      </div>
    </div>
  );

  const rightContent = (
    <div className={registrationFormCss}>
      {isClosed ? (
        <div className="closed-text">{renderRichText(closedText)}</div>
      ) : (
        <>
          <h2>{title}</h2>
          {error && (
            <>
              <p className="error">{error}</p>
              <br />
            </>
          )}
          <Form
            {...editableForm}
            rowsCollection={editableForm.rowsCollection}
            callback={onFormSubmit}
            extraParams={{ registration_token: getRegistrationToken()! }}
          />
        </>
      )}
    </div>
  );

  return (
    <section data-testid="registration-form" className={registrationContainerCss}>
      <BlockSplitPanel
        contentLeft={leftContent}
        contentRight={rightContent}
        leftBackgroundProps={{
          backgroundColor: BackgroundColor.White,
        }}
        rightBackgroundProps={{
          backgroundColor: BackgroundColor.Yellow,
        }}
      />
    </section>
  );
};
