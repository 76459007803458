import { css, cx } from '@emotion/css';
import { useImperativeEffect } from '@snapchat/core-browser';
import type { LottiePlayer } from 'lottie-web/build/player/lottie_light';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { logError } from '../../../../../helpers/logging';
import { generateSps2023Data } from './generate2023AnimationData';

type Sps2023AnimationProps = {
  className?: string;
  textLines: string[];
};

const animationContainerCss = css`
  /* To capture inner-animation attachment */
  position: relative;
  z-index: 0;

  /* Prevents the height from collapsing. */
  min-height: 215px;
`;

const animationInnerCss = css`
  /* To make it not animate over other container. Position required for z-index effect. */
  position: absolute;
  z-index: 0;

  /* Zoom in. Based on the % of container's width. Tuned to fit "REGISTERING," text. */
  width: 140%;

  /* Offset also has to be based on the same thing as width. */
  left: -31%;
  top: 0;
`;

const spacerCss = css`
  /* Forces the height to be at least 68% of the width. */
  padding-top: 68%;
`;

const animationName = 'spsAnimation2023';

export const Sps2023Animation: FC<Sps2023AnimationProps> = ({
  className,
  textLines,
  ...others
}) => {
  const [spsAnimationDiv, setSpsAnimationDiv] = useState<HTMLDivElement | null>(null);
  const setAnimationContainer = useCallback(
    (node: HTMLDivElement): void => setSpsAnimationDiv(node),
    []
  );

  const [lottie, setLottie] = useState<LottiePlayer | undefined>();

  // Lottie breaks on SSR due to a known bug: https://github.com/Gamote/lottie-react/issues/101
  // Workaround: Load Lottie dynamically on the client side before using it
  useImperativeEffect(() => {
    import('lottie-web/build/player/lottie_light')
      .then(({ default: dynamicLottie }) => {
        setLottie(dynamicLottie);
      })
      .catch(error => {
        logError({
          component: 'Sps2023Animation',
          message: 'Failed to load Lottie module',
          error,
        });
      });
  }, [setLottie]);

  useEffect(() => {
    if (!spsAnimationDiv || !lottie) return;

    const spsAnimation = lottie.loadAnimation({
      name: animationName,
      container: spsAnimationDiv, // the dom element that will contain the animation: ;
      // See: https://github.com/airbnb/lottie-web/wiki/Renderer-Settings
      rendererSettings: {
        // Crops the top whitespace.
        viewBoxSize: '0 30 750 750',
        // TODO: Figure out if we can crop the left and bottom within the SVG as well.
        // But we need the animation to bleed through.
        // viewBoxSize: '165 30 500 340',
        viewBoxOnly: true,
      },
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: generateSps2023Data(textLines), // the path to the animation json
    });

    /*
      Added this because the animation json fades out at the end. But we would
      like the end state to be the half way point where the the text is on screen.
    */
    spsAnimation.addEventListener('enterFrame', event => {
      const { currentTime, totalTime } = event;
      const halfwayPoint = totalTime / 2;

      if (currentTime >= halfwayPoint) {
        spsAnimation.pause();
      }
    });

    return () => lottie?.destroy(animationName);
  }, [lottie, spsAnimationDiv, textLines]);

  return (
    <figure
      data-testid="sps-animation-wrapper"
      className={cx(animationContainerCss, className)}
      {...others}
    >
      <div data-testid="sps-animation-spacer" className={spacerCss} />
      <div
        data-testid="sps-animation-canvas"
        ref={setAnimationContainer}
        className={cx(animationInnerCss, 'sps-animation-canvas')}
      ></div>
    </figure>
  );
};
