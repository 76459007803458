import { css } from '@emotion/css';
import { m, mobileMediaQuery, nonMobileMediaQuery } from '@snapchat/snap-design-system-marketing';

/** Wrapper to center the content of the home page (unless on mobile). */
export const sps2024HomeBlockCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  min-height: calc(100dvh - var(--total-header-height));
  margin-bottom: 50px;

  font-size: 16px;
  line-height: 28px;

  ${mobileMediaQuery} {
    width: 100%;
    height: unset;
  }
`;

export const gridContainerCss = css`
  /* grid setup */
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 180px;
  column-gap: 80px;
  padding: 0 40px;

  ${mobileMediaQuery} {
    width: 100%;
    gap: 40px;
    grid-template-columns: 1fr;
  }

  /* grid item */
  > * {
    align-self: center;
  }

  /* logo grid item */
  > img {
    width: 100%;
    max-width: 450px;
    /* Images should be right aligned on desktop */
    ${nonMobileMediaQuery} {
      justify-self: end;
    }
  }

  /* register button */
  .sdsm-button {
    margin: ${m('--spacing-xs')} 0px;
  }
`;

export const topAlignOnDesktopCss = css`
  ${nonMobileMediaQuery} {
    align-self: start;
  }
`;
