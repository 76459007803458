import { css } from '@emotion/css';

export const spsPlaygroundSectionCss = css`
  max-width: 1200px;
  margin: 64px auto 0px auto;

  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  button {
    padding: 4px 16px;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 8px;
    cursor: pointer;
  }
`;
