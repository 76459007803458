import { cx } from '@emotion/css';
import type { FC } from 'react';
import { useMemo } from 'react';

import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { useMobileLayout } from '../../../../hooks/useLayout';
import { renderRichText } from '../../../../utils/renderText/renderRichText';
import { Highlight } from '../Highlight';
import { Sps2023Animation } from '../Sps2023/Sps2023Animation';
import { VideoPreview } from '../VideoPreview/VideoPreview';
import type { SpsEventProps } from './eventQuery';
import {
  animationCss,
  blurbClass,
  blurbWithAnimationClass,
  highlightsSectionCss,
  highlightsTitleCss,
  learnMoreClass,
  streamReplayContainerCss,
  streamReplayInnerWrapperCss,
  videoPreviewClass,
} from './PostEvent.styled';

const animationTextLines = ['SNAP', 'PARTNER', 'SUMMIT', '2023'];

type Props = Pick<SpsEventProps, 'stream' | 'highlightsCollection' | 'highlightsSectionTitle'>;

/** Displays the Video-on-Demand (VOD) from the stream. */
export const PostEvent: FC<Props> = props => {
  const { stream, highlightsCollection, highlightsSectionTitle } = props;
  const features = useFeatureFlags();
  const enableBitmoji = features[Feature.EXPERIENCE_ENABLE_BITMOJI] === 'true';
  const enableCaptions = features[Feature.EXPERIENCE_ENABLE_CAPTIONS] === 'true';
  const isolateStreamReplay = features[Feature.EXPERIENCE_SHOW_ISOLATED_STREAM_REPLAY] === 'true';
  const isMobileLayout = useMobileLayout();

  // If the animation renders while hidden by css, the text will bunch up to the
  // left side of the canvas and be illegible. That requires us to re-render the
  // animation when the layout switches to a state that we know the animation will
  // now be visible, which is either...
  //
  // a) Mobile viewport when highlights are available
  // b) Any viewport when highlights are not available
  //
  // Finally, we wrap the animation in a useMemo to tell it to only re-render
  // when "isMobileLayout" changes values, otherwise, the animation will rerender
  // every time the user even so much as nudges the window size in either direction.
  const animation = useMemo(
    () =>
      !isMobileLayout || isolateStreamReplay ? (
        <Sps2023Animation className={animationCss} textLines={animationTextLines} />
      ) : null,
    [isMobileLayout, isolateStreamReplay]
  );

  return (
    <>
      <article
        className={cx(streamReplayContainerCss, {
          isolated: isolateStreamReplay,
        })}
      >
        <div className={cx(streamReplayInnerWrapperCss, { isolated: isolateStreamReplay })}>
          <VideoPreview
            className={videoPreviewClass}
            videoTitle={stream.title}
            videoUrl={stream.vodUrl}
            aslUrl={stream.aslVodUrl}
            videoBackgroundImage={stream.vodThumbnail}
            captionsCollection={stream.vodCaptionsCollection}
            bitmojiProps={{
              enableBitmoji,
              videoId: stream.sys.id,
            }}
            timestampBehavior={stream.vodTimestampBehavior}
            progressOffset={stream.vodProgressOffset}
            disableCaptions={!enableCaptions}
            analyticsId={stream.analyticsId}
          />
          <section className={blurbWithAnimationClass}>
            {animation}
            {renderRichText(stream.blurb ?? '')}
          </section>
          <p className={blurbClass}>{renderRichText(stream.blurb ?? '')}</p>
          {!isolateStreamReplay && (
            <img className={learnMoreClass} src="/learn-more.png" alt="Learn More" />
          )}
        </div>
      </article>
      {!isolateStreamReplay && (
        <>
          {highlightsSectionTitle && (
            <h2 className={highlightsTitleCss}>{highlightsSectionTitle}</h2>
          )}
          <section className={highlightsSectionCss}>
            {highlightsCollection.items.map(highlightProps => {
              return <Highlight key={highlightProps.sys.id} {...highlightProps} />;
            })}
          </section>
        </>
      )}
    </>
  );
};
