import { cx } from '@emotion/css';
import type {
  ClientBroadcastNotificaton,
  ReactionNotification,
  ReactionSubmitPayload,
} from '@snapchat/mw-common';
import {
  getBitmojiReactionEventKey,
  getLocalStorageItem,
  setLocalStorageItem,
} from '@snapchat/mw-common';
import { FormattedMessage } from '@snapchat/snap-design-system-marketing';
import throttle from 'lodash-es/throttle';
import type { FC } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { Socket } from 'socket.io-client';

import { logTiming } from '../../../../helpers/logging';
import { useBroadcast } from '../../../../hooks/useBroadcast';
import { useBitmojiVideoSync } from '../../hooks/useBitmojiReplays';
import { BitmojiContext } from '../BitmojiProvider';
import { bitmojiReactionsCss, bitmojiReactionsNoBitmojiTextCss } from './BitmojiControls.styles';
import type { BitmojiControlsProps } from './BitmojiControls.types';
import { BitmoReactionToggle } from './BitmojiReactionToggle';
import { getBitmojiReactionSocket } from './getBitmojiReactionSocket';
import { ReactionButton } from './ReactionButton';
import { SnapKitLoginButton } from './SnapKitLoginButton';

const reactionIds = ['20082396', '20082398', '20082395', '20082657', '20082661'];

// Singleton that is managed by component, only creates when logged in
let reactionSocket: Socket | undefined;

const isReactionTimeInVideoWindow = (currentVideoTime?: number, reactionVideoTimeUtc?: number) => {
  if (currentVideoTime === undefined || reactionVideoTimeUtc === undefined) return false;

  // Allow people to see reactions from earlier in real time, while later are synced at the right time
  return (
    currentVideoTime - 7e3 < reactionVideoTimeUtc && reactionVideoTimeUtc < currentVideoTime + 1e3
  );
};

export const BitmojiControls: FC<BitmojiControlsProps> = ({
  hideBitmoji,
  hideBitmojiClass,
  setHideBitmoji,
  videoTimestampRef,
  bitmojiProps,
  analyticsId,
  buttonsDisabled,
}) => {
  const [accessToken, setAccessToken] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const { sendToBitmojiStream } = useContext(BitmojiContext);

  // use ref for needed values that shouldn't be redone on renders
  const accessTokenRef = useRef('');
  const avatarIdRef = useRef('');

  const setCredentials = useCallback(
    (bitmojiId: string | undefined, snapToken: string) => {
      // Set the access token
      setLocalStorageItem('mwp-snapkit-access-token', snapToken);
      setAccessToken(snapToken);
      accessTokenRef.current = snapToken;

      // Snapchat accounts without bitmoji avatars will not have a bitmoji id
      if (!bitmojiId) return;

      setLocalStorageItem('mwp-snapkit-user-id', bitmojiId);
      setAvatarId(bitmojiId);
      avatarIdRef.current = bitmojiId;
    },
    [setAvatarId, setAccessToken]
  );

  // inital credentials setup and listening for visibility
  useEffect(() => {
    const localAvatarId = getLocalStorageItem('mwp-snapkit-user-id') ?? '';
    const localAccessToken = getLocalStorageItem('mwp-snapkit-access-token') ?? '';

    if (localAccessToken) {
      setCredentials(localAvatarId, localAccessToken);
    }
  }, [setCredentials]);

  // ==========================================================================
  // Receiving reactions from the broadcast service
  // ==========================================================================

  // Need to keep live reactions in sync with the video since user can be 10+ seconds behind others.
  // Send any reactions that are out of our video view window to be played later.
  const { syncBitmojiReactions } = useBitmojiVideoSync(videoTimestampRef, bitmojiProps);

  const onBitmojiReaction = useCallback(
    (notification: ClientBroadcastNotificaton) => {
      const reaction = notification as ReactionNotification;
      const { bitmojiId, bitmojiReactionType, clientTimestamp } = reaction;

      clientTimestamp &&
        bitmojiId === avatarIdRef.current &&
        logTiming({
          eventVariable: 'reaction_total_duration',
          eventValue: Date.now() - new Date(clientTimestamp).getTime(),
          eventCategory: 'SpsLogin',
        });

      if (bitmojiId === avatarIdRef.current) {
        return; // if the user submitted this bitmoji no need to render during live at all.
      }

      if (!sendToBitmojiStream) return;

      if (isReactionTimeInVideoWindow(videoTimestampRef.current, reaction.videoTime)) {
        // draw bitmoji immediately if its within the time window, else let the useBitmojiVideoSync handle it
        sendToBitmojiStream(bitmojiReactionType, bitmojiId);
      } else {
        syncBitmojiReactions([reaction]);
      }
    },
    [syncBitmojiReactions, sendToBitmojiStream, videoTimestampRef]
  );

  const reactionVideoEventKey = getBitmojiReactionEventKey(bitmojiProps.videoId);

  useBroadcast(reactionVideoEventKey, {
    onMessage: onBitmojiReaction,
  });

  // ==========================================================================
  // Submitting reactions if the user is logged in
  // ==========================================================================

  // update reaction socket if certain settings change
  useEffect(() => {
    if (!accessToken) return;

    reactionSocket = getBitmojiReactionSocket({
      accessToken,
      onExpiredCredentials: () => setCredentials('', ''),
    });

    // destroy socket when credentials update
    return () => {
      reactionSocket?.close();
      reactionSocket = undefined;
    };
  }, [accessToken, setCredentials]);

  // Throttles any emissions to be one per second.
  const sendToBrs = useMemo(
    () =>
      throttle((reactionRequest: ReactionSubmitPayload) => {
        reactionSocket?.emit('submitReaction', reactionRequest);
      }, 1000),
    []
  );

  // ==========================================================================
  // Render Logic
  // ==========================================================================

  const snapkitLoggedIn = accessToken ? 'snapkit-logged-in' : '';

  const renderControls = () => {
    if (!accessToken) {
      return (
        <SnapKitLoginButton buttonText="Sign in to use Bitmoji" onAuthenticated={setCredentials} />
      );
    }

    if (avatarId) {
      return reactionIds.map(reactionId => (
        <ReactionButton
          key={reactionId}
          reactionId={reactionId}
          avatarId={avatarId}
          videoTimestampRef={videoTimestampRef}
          sendToBrs={sendToBrs}
          videoId={bitmojiProps.videoId}
          analyticsId={analyticsId}
          isDisabled={buttonsDisabled}
        />
      ));
    }

    return (
      <p className={bitmojiReactionsNoBitmojiTextCss}>
        <FormattedMessage
          id="accountHasNoBitmoji"
          defaultMessage="Set up your Bitmoji avatar in the Snapchat app to send Bitmoji reactions!"
        />
      </p>
    );
  };

  return (
    <section className={cx(snapkitLoggedIn, hideBitmoji, hideBitmojiClass)}>
      <article className={bitmojiReactionsCss}>
        <BitmoReactionToggle
          hideBitmoji={hideBitmoji}
          avatarId={avatarId}
          setHideBitmoji={setHideBitmoji}
        />
        {renderControls()}
      </article>
    </section>
  );
};
