import gql from 'graphql-tag';

import type { ContentfulTypedSysProps } from '../../../../../types/contentful';
import type { Items } from '../../../../../types/Items';

const playgroundFragment = gql`
  fragment SpsPlaygroundAll on SpsPlayground {
    sys {
      id
      publishedAt
    }
    polledText
  }
`;

interface PlaygroundFragment {
  polledText: string;
  sys: ContentfulTypedSysProps<'SpsPlayground'> & { publishedAt: string };
}

export const spsPlaygroundCollectionQuery = gql`
  query SpsPlaygroundCollectionQuery($locale: String!, $preview: Boolean!) {
    spsPlaygroundCollection(locale: $locale, preview: $preview) {
      items {
        ...SpsPlaygroundAll
      }
    }
  }
  ${playgroundFragment}
`;

export interface SpsPlaygroundCollectionData {
  spsPlaygroundCollection: Items<PlaygroundFragment>;
}

export const spsPlaygroundQuery = gql`
  query SpsPlaygroundQuery($id: String!, $locale: String!, $preview: Boolean!) {
    spsPlayground(id: $id, locale: $locale, preview: $preview) {
      ...SpsPlaygroundAll
    }
  }
  ${playgroundFragment}
`;

export interface SpsPlaygroundData {
  spsPlayground: PlaygroundFragment;
}
