import { css } from '@emotion/css';
import {
  largeDesktopMediaQuery,
  mobileMediaQuery,
  nonMobileMediaQuery,
} from '@snapchat/snap-design-system-marketing';

export const spsLogoCharCountCssVar = '--sps-logo-char-count';

// Registration state styles

export const registrationFormCss = css`
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;

  ${largeDesktopMediaQuery} {
    padding-left: 1vw;
  }

  .closed-text {
    line-height: 28px;
  }

  h2 {
    padding-bottom: 20px;
  }

  .error {
    color: #e93939;
  }

  /* override form button alignment */
  form {
    align-items: flex-start;
    max-width: 510px;
  }
  form > :nth-last-child(2) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const registrationEventCss = css`
  min-height: 70px;
  /* To render above the logo animation background */
  z-index: 1;
`;

export const registrationEventContainerCss = css`
  overflow: hidden;

  margin-top: 32px;
  max-height: calc(100vh - var(--total-header-height));
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-evenly;

  max-width: 600px;
  ${nonMobileMediaQuery} {
    /* Right-align within the split panel block */
    margin-left: auto;
    margin-right: 0;
  }

  ${largeDesktopMediaQuery} {
    margin-right: 6vw;
  }

  padding-left: 12.5%;
  ${mobileMediaQuery} {
    padding-left: unset;
  }
`;

export const registrationContainerCss = css`
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
  }

  /* make full screen when split is side by side */
  ${nonMobileMediaQuery} {
    height: 100vh;
    height: 100dvh;
    /* Use a negative margin so the container will overlap with the transparent header */
    margin-top: calc(var(--total-header-height) * -1);
  }

  /* Hides the scrollbar in the middle of the split block panel.
   * Hack so we can avoid modifying SDS-M on a strict timeline. */
  &&& > * > * {
    overflow: hidden;
    padding: 32px;
  }
`;

// Registration result and welcome back styles

export const registrationResultContainerCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center; /* To center-align the text */

  > * {
    max-width: 634px;
  }

  ${mobileMediaQuery} {
    display: inline;

    justify-content: left;
    align-items: left;
    text-align: left;

    > * {
      padding: 32px;
      position: relative; /* to get zindex to work smh */
      max-width: unset;
    }
    overflow: hidden; /* to not let the animation cause the page to need horizontal scroll. */
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
  }

  .primary-text {
    padding: 0 32px;
    font-size: 20px;
    line-height: 28px;
    ${mobileMediaQuery} {
      font-size: 16px;
      padding-top: 0;
    }
  }

  &&& {
    h1 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      padding-bottom: 24px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      padding-bottom: 8px;
    }
    ${mobileMediaQuery} {
      h1 {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .event-info {
    ${nonMobileMediaQuery} {
      padding-top: 48px;
    }
  }

  .event-info-text {
    line-height: 26px;
  }

  .save-the-date {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  button {
    margin-bottom: 32px;
    ${nonMobileMediaQuery} {
      margin-bottom: 124px;
    }
  }

  /* Make everything that's not the animation render in front, so that the animation does not paint over it. */
  & > *:not(figure) {
    z-index: 1;
  }
`;

/**
 * Used to offset the logo alignment by an amount based on the character count of the longest line.
 *
 * NOTES: value is very fragile, dependent on animation file at time of writing and styling of the
 * registration page with % based logo alignment. must be recalculated if the animation file changes
 * (particularly font size, style, positioning). value calculated by:
 *
 * - Calculating offset value to align text such that left-most character is centered on page
 *   (measured as left: 0%)
 * - Editing logo text to maximum expected value (12 characters for 'REGISTERING,')
 * - Aligning maximum logo text to be center aligned (measured as left: -44%)
 * - Divide the maximum value by number of characters e.g. -44 / 12 = -3.66
 */
const characterOffsetPercentage = -3.66;

export const registrationResultAnimationCss = css`
  width: 400px;
  margin-top: 32px;
  margin-left: 96px;

  ${nonMobileMediaQuery} {
    /* Center the logo by calculating offset by longest line of text */
    .sps-animation-canvas {
      left: calc(${characterOffsetPercentage}% * var(${spsLogoCharCountCssVar}));
    }
  }

  ${mobileMediaQuery} {
    margin-top: 32px;
    margin-left: 32px;
    width: calc(100vw - 64px);
  }
`;

export const regAddCalendarCss = css`
  list-style: none;
  margin-bottom: 30px;
  li {
    display: inline-block;
    img {
      width: 50px;
    }
  }
  li:not(:first-child) {
    margin-left: 44px;
  }
`;

export const topPaddingCss = css`
  margin-top: 32px;
`;
