import { css } from '@emotion/css';
import { mobileMediaQuery, Spacing } from '@snapchat/snap-design-system-marketing';

export const bookmarkToastTextCss = css`
  margin-bottom: 16px;
`;

/** In mobile, the bookmark toast should display from the bottom of the screen. */

export const bookmarkToastCss = css`
  ${mobileMediaQuery} {
    bottom: ${Spacing.XS}px;
    left: 0;
    right: 0;
    top: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;
