import type {
  AkamaiMediaConfiguration,
  AkamaiPlayerConfiguration,
  AkamaiPluginConfiguration,
} from '../../../../types/akamai';

type AkamaiPlugins = 'mediaanalytics' | 'react' | 'admarker' | 'hls';

export const akamaiScriptSrc =
  'https://amp.akamaized.net/hosted/1.1/player.esi?apikey=snap&format=script&version=9.1.22';

const akamaiResourceUrlPrefix = 'https://amp.akamaized.net/players/9.1.22+premier';

const akamaiPlugins: Partial<Record<AkamaiPlugins, AkamaiPluginConfiguration>> = {
  // https://player.akamai.com/documentation/web/amp-web-media-analytics/tutorial-1-overview.html
  mediaanalytics: {
    resources: [
      {
        src: 'https://79423.analytics.edgekey.net/ma_library/javascript/javascript_malibrary.js',
        type: 'text/javascript',
        async: true,
      },
      {
        src: `${akamaiResourceUrlPrefix}/akamai/amp/mediaanalytics/Mediaanalytics.min.js`,
        debug: `${akamaiResourceUrlPrefix}/akamai/amp/mediaanalytics/Mediaanalytics.js`,
        type: 'text/javascript',
        async: true,
      },
    ],
    config: 'https://ma1573-r.analytics.edgekey.net/config/beacon-31264.xml',
    iplookup: false,
    dimensions: {
      title: 'SPS Event', // generic placeholder - overwritten when used in `buildAkamaiPlayerConfig()`
      playerId: 'SPS Event Player', // generic placeholder - overwritten when used in `buildAkamaiPlayerConfig()`
    },
  },
  // https://player.akamai.com/documentation/web/amp-web-react/tutorial-1-overview.html
  react: {
    resources: [
      {
        src: `${akamaiResourceUrlPrefix}/akamai/amp/react/libs/react.min.js`,
        debug: `${akamaiResourceUrlPrefix}/akamai/amp/react/libs/react.js`,
        type: 'text/javascript',
      },
      {
        src: `${akamaiResourceUrlPrefix}/akamai/amp/react/React.min.css`,
        debug: `${akamaiResourceUrlPrefix}/akamai/amp/react/React.css`,
        type: 'text/css',
      },
      {
        src: `${akamaiResourceUrlPrefix}/akamai/amp/react/React.js`,
        debug: `${akamaiResourceUrlPrefix}/akamai/amp/react/React.js`,
        type: 'text/javascript',
      },
    ],
    hint: {
      enabled: true,
      mode: 'blocked',
    },
    autoHide: 999999,
  },
  // NOTE: workaround for race condition error in refactored code.
  hls: {
    resources: [
      {
        src: `${akamaiResourceUrlPrefix}/akamai/amp/core/libs/hls.min.js`,
        type: 'text/javascript',
      },
    ],
  },
};

/**
 * Akamai Player configuration template. This is the core configuration for the current year. If you
 * need to set up additional configurations for prior years, etc. create new configuration templates
 * and use in the wrapping constructor function.
 */
const akamaiPlayerConfigTemplate: AkamaiPlayerConfiguration = {
  autoplay: true,
  fullscreen: {
    enabled: true,
  },
  playsinline: true,
  // TODO: Pull this language from the current locale.
  language: 'en',
  locales: {
    // Needed for the caption selector to display correct labels for Hindi, Arabic, and Danish
    en: {
      MSG_HI: 'Hindi',
      MSG_AR: 'Arabic',
      MSG_DA: 'Danish',
    },
  },
  plugins: {
    mediaanalytics: akamaiPlugins.mediaanalytics,
    react: akamaiPlugins.react,
    // Temporary workaround - default behavior defers loading this resource but this causes a race condition with the refactored code.
    // TODO: Figure out better resolution for this.
    hls: akamaiPlugins.hls,
  },
  captioning: {
    // render html overlay when using Javascript player, and native captions for device players
    renderer: 'auto',
    enabled: true,
  },
  // These settings will help achieve a seamless transition from backup to primary streams.
  // Smaller buffer size allows for faster switching from backup to primary and vice versa.
  hls: {
    data: {
      backBufferLength: 0,
      maxBufferLength: 12,
    },
  },
};

/**
 * Constructs an Akamai Player configuration using the default configuration and passed in media
 * settings.
 */
export const buildAkamaiPlayerConfig = (
  media: AkamaiMediaConfiguration,
  playerId?: string,
  disableCaptions = false
): AkamaiPlayerConfiguration => {
  const mediaTitle = media.title || 'unknown'; // purposely using OR here to apply default for empty strings as well.
  const cmcd = {
    enabled: true,
    contentId: mediaTitle,
  };
  const config = { ...akamaiPlayerConfigTemplate, media, cmcd };

  if (disableCaptions) {
    config.captioning = {
      ...akamaiPlayerConfigTemplate.captioning,
      enabled: false,
    };
  }

  if (config.plugins?.mediaanalytics) {
    config.plugins.mediaanalytics.dimensions = {
      title: mediaTitle,
      playerId: playerId || 'Akamai Player', // purposely using OR here to apply default for empty strings as well.
    };
  }

  return config;
};
