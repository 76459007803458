import { css } from '@emotion/css';
import { BackgroundColor, globalHeaderHeight } from '@snapchat/snap-design-system-marketing';

export const liveEventWrapperCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: calc(100vh - ${globalHeaderHeight}px);
  min-height: calc(100dvh - ${globalHeaderHeight}px);
`;

export const darkModeCss = css`
  background-color: ${BackgroundColor.Black};
`;
