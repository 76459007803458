import { gql } from '@apollo/client';

import { blockAllFragment } from '../../../../../components/Block';
import { contentfulSysIdFragment } from '../../../../../utils/contentful/contentfulQuery';

export const confirmationFragment = gql`
  fragment ConfirmationAll2023 on SpsConfirmation2023 {
    title
    primaryBlurb {
      json
    }
    middleBlurb {
      json
    }
    secondaryBlurb {
      json
    }
    showSaveTheDate
    showStartOverButton
    buttonText
    belowTheFoldCollection(limit: 5) {
      items {
        ...ContentfulSysId
        ... on Block {
          ...BlockAll
        }
      }
    }
    ...ContentfulSysId
  }
  ${blockAllFragment}
  ${contentfulSysIdFragment}
`;
