import { useImperativeEffect } from '@snapchat/core-browser';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@snapchat/mw-common';
import type { FormBody, MultiSelectOption } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';
import { useContext, useState } from 'react';

import { AppContext } from '../../../../../AppContext';
import type { SpsSiteData } from '../../../types';
import { SpsRegistrationStatus } from '../../../types';
import { RegistrationForm } from './RegistrationForm';
import { RegistrationResult } from './RegistrationResult';
import type { Sps2024RegistrationProps } from './Sps2024RegistrationQuery';

enum FormState {
  ERROR = 'Error',
  FAILED = 'Failed',
  INITIALIZING = 'Initializing',
  REGISTERED = 'Registered',
  UNREGISTERED = 'Unregistered',
  WELCOME_BACK = 'WelcomeBack',
}

// TODO Do these need to be changed?
export const firstNameStorageKey = 'sps-2024-firstName';
export const registrationStatusStorageKey = 'sps-2024-registrationStatus';
export const interestsStorageKey = 'sps-2024-interests';

const defaultAnimationLines = ['SNAP', 'PARTNER', 'SUMMIT', '2024'];

export const Sps2024Registration: FC<Sps2024RegistrationProps> = ({
  blurb,
  formTitle,
  formErrorText,
  allowlistErrorText,
  registrationForm,
  irlConfirmation,
  virtualConfirmation,
  notAttendingConfirmation,
  intermediateConfirmation,
  errorPage,
  defaultAnimationTextLines,
  personalizedAnimationTextLines,
  noNameAnimationTextLines,
  requireEmailToken,
  isClosed,
  closedText,
}) => {
  const siteData = useContext(AppContext).siteData as SpsSiteData;

  // ==========================================================================
  // State
  // ==========================================================================

  const [registrationStatus, setRegistrationStatus] = useState<SpsRegistrationStatus | undefined>();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [formState, setFormState] = useState<FormState>(FormState.INITIALIZING);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>();

  // Initialize state. Must be done in useEffect since localStorage is not available on SSR
  useImperativeEffect(() => {
    // Determine the status of a user in one of two ways depending on if the email token authentication is required to
    // for this form (i.e. requireEmailToken === true)
    //
    // a) If the registration requires a token, then Salesforce is the place to look for status/first name. Salesforce will
    // have been queried during the SSR, and the resulting status/first name should then be available in siteData in this case.
    //
    // b) For unauthenticated flow, simply check local storage for a previously submitted status/first name on this device.

    const initialRegistrationStatus = requireEmailToken
      ? siteData.status
      : (getLocalStorageItem(registrationStatusStorageKey) as SpsRegistrationStatus | null) ??
        undefined;

    const initialFirstNameState = requireEmailToken
      ? siteData?.firstName
      : (getLocalStorageItem(firstNameStorageKey) as string | null) ?? undefined;

    // If registration status was found (either from salesforce or local storage), then we know this is a returning
    // user and we should show the welcome back screen. Otherwise, show the registration form.
    const initialFormState = initialRegistrationStatus
      ? FormState.WELCOME_BACK
      : FormState.UNREGISTERED;

    setRegistrationStatus(initialRegistrationStatus);
    setFirstName(initialFirstNameState);
    setFormState(initialFormState);
  }, [siteData.status, siteData.decodedEmail, requireEmailToken, siteData?.firstName]);

  // ==========================================================================
  // Helper Functions
  // ==========================================================================

  const scrollToTop = () => scroll(0, 0);

  // ==========================================================================
  // Callbacks
  // ==========================================================================

  const onFormSubmit = (response: Response, formBody: FormBody) => {
    if (response.ok) {
      const firstNameField = (formBody['first_name'] ?? '') as string;
      const newRegistrationStatus = (formBody['registration_status'] ?? 'Virtual') as string;

      // For virtual flow, we persist name and status in local storage for confirmation ui so that
      // returning user can see their name and status
      if (!requireEmailToken) {
        // TODO: fix this in subsequent ticket: ENTWEB-7221
        setLocalStorageItem(firstNameStorageKey, firstNameField);
        setLocalStorageItem(registrationStatusStorageKey, newRegistrationStatus);

        if (formBody['interests']) {
          const selectedInterests = (formBody['interests'] as MultiSelectOption[]).reduce(
            (prev, curr, idx) => {
              return idx === 0 ? curr.name : `${prev},${curr.name}`;
            },
            ''
          );
          setLocalStorageItem(interestsStorageKey, selectedInterests);
        }
      }

      setFirstName(firstNameField);
      setRegistrationStatus(newRegistrationStatus as SpsRegistrationStatus);
      setFormState(FormState.REGISTERED);
    } else {
      const errorMessage = response.status === 403 ? allowlistErrorText : formErrorText;
      setFormErrorMessage(errorMessage);
      setFormState(FormState.FAILED);
    }

    scrollToTop();
  };

  const onStartOver = () => {
    removeLocalStorageItem(registrationStatusStorageKey);
    removeLocalStorageItem(firstNameStorageKey);
    setFormState(FormState.UNREGISTERED);
    scrollToTop();
  };

  // ==========================================================================
  // Getters
  // ==========================================================================

  const getPersonalizedAnimationText = () => {
    // Prefer to get name from site data, which will be populated if the user accessed
    // this page with a registration token
    const name = firstName ?? getLocalStorageItem(firstNameStorageKey);
    if (!name || name.length > 8) return noNameAnimationTextLines;
    return personalizedAnimationTextLines?.map(line =>
      line.replace('{firstName}', name.toLocaleUpperCase())
    );
  };

  const getFormContent = (errorMessage?: string) => {
    return (
      <RegistrationForm
        blurb={blurb}
        title={formTitle}
        animationTextLines={defaultAnimationTextLines ?? []}
        form={registrationForm}
        spsRegistrationEmail={siteData.decodedEmail}
        onFormSubmit={onFormSubmit}
        error={errorMessage}
        isClosed={isClosed}
        closedText={closedText}
      />
    );
  };

  const getConfirmationContent = (isReturningUser = false) => {
    let animationTextLines = defaultAnimationTextLines;

    if (!isReturningUser && registrationStatus !== SpsRegistrationStatus.IrlNo) {
      animationTextLines = getPersonalizedAnimationText();
    }

    if (registrationStatus === SpsRegistrationStatus.IrlNo) {
      return (
        <RegistrationResult
          animationTextLines={animationTextLines ?? []}
          isReturningUser={false} // Hardcode false for "No" state since title should come from Contentful regardless
          firstName={firstName}
          showUsernameForm={false}
          registrationStatus={registrationStatus}
          {...notAttendingConfirmation}
        />
      );
    }

    // intermediate for IRL only immediately after filling out form.
    if (!isReturningUser && registrationStatus === SpsRegistrationStatus.IrlYes) {
      return (
        <RegistrationResult
          animationTextLines={animationTextLines ?? []}
          isReturningUser={false} // always false to pull title from contentful
          firstName={firstName}
          registrationStatus={registrationStatus}
          showUsernameForm={false}
          {...intermediateConfirmation}
        />
      );
    }

    if (registrationStatus === SpsRegistrationStatus.Virtual) {
      return (
        <RegistrationResult
          animationTextLines={animationTextLines ?? []}
          isReturningUser={isReturningUser}
          firstName={firstName}
          registrationStatus={registrationStatus}
          showUsernameForm={false}
          onStartOver={
            registrationStatus === SpsRegistrationStatus.Virtual && !requireEmailToken
              ? onStartOver
              : undefined
          }
          {...virtualConfirmation}
        />
      );
    }

    // returning IRL user
    return (
      <RegistrationResult
        animationTextLines={animationTextLines ?? []}
        isReturningUser={isReturningUser}
        firstName={firstName}
        registrationStatus={registrationStatus}
        showUsernameForm={!siteData?.username}
        {...irlConfirmation}
      />
    );
  };

  const getErrorContent = () => {
    return (
      <RegistrationResult
        animationTextLines={defaultAnimationLines}
        showUsernameForm={false}
        {...errorPage}
      />
    );
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <div>
      {formState === FormState.UNREGISTERED && getFormContent()}
      {formState === FormState.REGISTERED && getConfirmationContent()}
      {formState === FormState.WELCOME_BACK && getConfirmationContent(true)}
      {formState === FormState.FAILED && getFormContent(formErrorMessage)}
      {formState === FormState.ERROR && getErrorContent()}
      {/* and if formState === FormState.Initializing then simply render nothing */}
    </div>
  );
};
