import { Button, ButtonType } from '@snapchat/snap-design-system-marketing';
import type { CSSProperties, FC } from 'react';

import { Anchor } from '../../../../../components/Anchor';
import { Block } from '../../../../../components/Block/Block';
import { Feature, useFeatureFlags } from '../../../../../components/FeatureFlags';
import { renderRichText } from '../../../../../utils/renderText/renderRichText';
import { Sps2023Animation } from '../Sps2023Animation';
import { Sps2023RegistrationStatus } from '../types';
import {
  regAddCalendarCss,
  registrationResultAnimationCss,
  registrationResultContainerCss,
  spsLogoCharCountCssVar,
  topPaddingCss,
} from './sps2023Registration.styled';
import type { ConfirmationDataProps } from './Sps2023RegistrationQuery';

interface RegistrationResultProps extends ConfirmationDataProps {
  animationTextLines: string[];
  isReturningUser?: boolean;
  firstName?: string;
  registrationStatus?: Sps2023RegistrationStatus;
  onStartOver?: () => void;
}

// Helpful Tool to generate these : http://icalgen.yc.sg/, https://ical.marudot.com/
const irlGoogleEventLink =
  'https://calendar.google.com/calendar/u/0/r/eventedit?text=Snap+Partner+Summit+2023&dates=20230419/20230420&details=Please+join+us+in+person+for+our+fifth+annual+Snap+Partner+Summit+on+April+19,+2023.+We%E2%80%99re+excited+to+share+news+about+our+latest+innovations,+partnerships,+and+creator+tools+that+enhance+the+Snapchat+community%E2%80%99s+relationships+with+their+friends,+family,+and+the+world+around+them.+%0A%0ADoors+open+at+8%3A30+AM+for+breakfast+with+the+show+beginning+promptly+at+10%3A00+AM.&location=The+Barker+Hangar,+3021+Airport+Ave+Suite+203,+Santa+Monica,+CA+90405,+USA&trp=true&sf=true&output=xml#f';
const virtualGoogleEventLink =
  'https://www.google.com/calendar/render?action=TEMPLATE&text=Snap%20Partner%20Summit%202023&dates=20230419T170000Z/20230419T183000Z&details=%20Please%20join%20us%20for%20our%20fifth%20annual%20Snap%20Partner%20Summit%20on%20April%2019%2C%202023.%20We%E2%80%99re%20excited%20to%20share%20news%20about%20our%20latest%20innovations%2C%20partnerships%2C%20and%20creator%20tools%20that%20enhance%20the%20Snapchat%20community%E2%80%99s%20relationships%20with%20their%20friends%2C%20family%2C%20and%20the%20world%20around%20them.%20Join%20at%20snappartnersummit.com.&location=https%3A%2F%2Fwww.snappartnersummit.com%2F&trp=true&sf=true&output=xml#f';

const calendarData = (attendanceValue?: Sps2023RegistrationStatus) => {
  switch (attendanceValue) {
    case Sps2023RegistrationStatus.InvitedInPerson:
      return {
        googleEventLink: irlGoogleEventLink,
        icsUrl: '/sps-irl-2023.ics',
      };
    case Sps2023RegistrationStatus.InvitedVirtual:
    case Sps2023RegistrationStatus.Virtual:
      return {
        googleEventLink: virtualGoogleEventLink,
        icsUrl: '/sps-virtual-2023.ics',
      };
  }

  return { googleEventLink: '', icsUrl: '' };
};

export const RegistrationResult: FC<RegistrationResultProps> = ({
  firstName,
  title,
  animationTextLines,
  primaryBlurb,
  middleBlurb,
  secondaryBlurb,
  showSaveTheDate,
  showStartOverButton,
  buttonText,
  registrationStatus,
  isReturningUser,
  onStartOver,
  belowTheFoldCollection,
}) => {
  const features = useFeatureFlags();

  // Only render stream link to virtual attendees when feature flag is enabled
  const showGoToStreamButton =
    features[Feature.SPS_SHOW_GO_TO_STREAM_BUTTON] === 'true' &&
    (registrationStatus === Sps2023RegistrationStatus.Virtual ||
      registrationStatus === Sps2023RegistrationStatus.InvitedVirtual);

  const { googleEventLink, icsUrl } = calendarData(registrationStatus);

  const longestLineLength = animationTextLines.reduce((val, current) => {
    return Math.max(val, current.length);
  }, 0);

  if (isReturningUser) {
    title = `Welcome back, ${firstName}!`;
  }

  return (
    <>
      <div
        data-testid="sps-registration-result"
        className={registrationResultContainerCss}
        style={{ [spsLogoCharCountCssVar]: longestLineLength } as CSSProperties}
      >
        <Sps2023Animation
          className={registrationResultAnimationCss}
          textLines={animationTextLines}
        />
        <h1 className={!animationTextLines[-1] ? topPaddingCss : ''}>{title}</h1>
        {primaryBlurb && <div className="primary-text">{renderRichText(primaryBlurb)}</div>}
        {registrationStatus && registrationStatus !== Sps2023RegistrationStatus.InvitedNo && (
          <div className="event-info">
            {middleBlurb && <div>{renderRichText(middleBlurb)}</div>}
            {showGoToStreamButton && (
              <Button type={ButtonType.Primary} link="/">
                Watch the Replay
              </Button>
            )}
            {secondaryBlurb && (
              <div className="address-section">
                <br />
                <div className="event-info-text">{renderRichText(secondaryBlurb)}</div>
              </div>
            )}
          </div>
        )}
        {showSaveTheDate && (
          <div className="save-the-date">
            <h1>Save the date</h1>
            <ul className={regAddCalendarCss}>
              <li>
                <Anchor href={googleEventLink}>
                  <img src="/gcal.png" />
                </Anchor>
              </li>
              <li>
                <Anchor href={icsUrl}>
                  <img src="/ical.png" />
                </Anchor>
              </li>
              <li>
                <Anchor href={icsUrl}>
                  <img src="/outlook.png" />
                </Anchor>
              </li>
            </ul>
          </div>
        )}
        <div data-testid="registration-result-action">
          {showStartOverButton && onStartOver && (
            <Button type={ButtonType.Secondary} onClick={onStartOver}>
              {buttonText}
            </Button>
          )}
          {!registrationStatus && (
            /* For an invalid status show return to homepage button */
            <Button type={ButtonType.Primary} link="/">
              {buttonText}
            </Button>
          )}
        </div>
      </div>
      {belowTheFoldCollection?.items.map(props => {
        const { sys, ...blockProps } = props;
        return (
          <Block
            sys={sys}
            key={sys.id}
            {...blockProps}
            backgroundColor={blockProps.backgroundColor}
          />
        );
      })}
    </>
  );
};
