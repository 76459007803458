import { css } from '@emotion/css';
import { mobileMediaQuery } from '@snapchat/snap-design-system-marketing';

export const sps2023HomeBlockCss = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  height: calc(100dvh - var(--total-header-height));
  margin-bottom: 50px;

  font-size: 16px;
  line-height: 28px;

  & > * {
    width: 50%;
    max-width: 720px;
  }

  & article {
    padding: 0 40px;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
    height: unset;

    & > * {
      width: 100%;
    }

    & article {
      padding: 0 32px;
    }
  }
`;

export const homeInfoCss = css`
  /* Need to set z-index to allow rendering this over the white of the animation. */
  z-index: 1;

  & > p {
    margin-block-start: 12px;
    margin-block-end: 12px;
  }

  & > h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  & > h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
`;
