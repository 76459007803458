import gql from 'graphql-tag';

import type { ButtonData } from '../../../../../components/Button';
import { buttonFragment } from '../../../../../components/Button';
import type { ContentfulTypedSysProps } from '../../../../../types/contentful';
import type { RichText } from '../../../../../types/RichText';
import { contentfulSysIdFragment } from '../../../../../utils/contentful/contentfulQuery';

export const spsHome2023Query = gql`
  query SpsHome2023Query($id: String!, $locale: String!, $preview: Boolean!) {
    spsHome2023(id: $id, locale: $locale, preview: $preview) {
      ...ContentfulSysId
      logoLines
      title
      subtitle
      label
      body {
        json
      }
      callToAction {
        ...ButtonAll
      }
    }
  }
  ${contentfulSysIdFragment}
  ${buttonFragment}
`;

export interface SpsHome2023Props {
  spsHome2023: ContentfulTypedSysProps<'SpsHome2023'> & {
    logoLines: string[];
    title: string;
    subtitle: string;
    label: string;
    body: RichText;
    callToAction: ButtonData;
  };
}
