import {
  defaultMotif,
  mergeMotifs,
  MotifComponent,
  MotifScheme,
} from '@snapchat/snap-design-system-marketing';

export const spsMotif = mergeMotifs(defaultMotif, {
  name: 'SPS Motif',
  [MotifScheme.DEFAULT]: {
    name: 'Default with transparent header and transparent loading bar',
    [MotifComponent.HEADER]: {
      '--global-header-bg-color': 'transparent',
    },
    // Hide loading bar (visible loading bar clashes with SPS branding)
    [MotifComponent.LOADING_BAR]: {
      '--loading-bar-left-color': 'transparent',
      '--loading-bar-right-color': 'transparent',
    },
  },
  [MotifScheme.TERTIARY]: {
    name: 'Tertiary with transparent header',
    [MotifComponent.HEADER]: {
      '--global-header-bg-color': 'transparent',
    },
  },
});
