import { css } from '@emotion/css';
import {
  mobileMediaQuery,
  nonMobileMediaQuery,
  Spacing,
} from '@snapchat/snap-design-system-marketing';

import { totalHeaderHeightCssVar } from '../../../../components/Header/headerSizeUtils';

const isolatedStreamReplayClass = 'isolated';

export const videoPreviewClass = 'video-preview';
export const learnMoreClass = 'learn-more';
export const blurbClass = 'blurb';
export const blurbWithAnimationClass = 'blurb-with-animation';

export const streamReplayContainerCss = css`
  position: relative;
  height: calc(100vh - var(${totalHeaderHeightCssVar}));
  height: calc(100dvh - var(${totalHeaderHeightCssVar}));
  max-height: 510px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  ${mobileMediaQuery} {
    max-height: unset;
  }

  /* Overrides for isolated layout */
  &.${isolatedStreamReplayClass} {
    height: unset;
    max-height: unset;
    min-height: calc(100vh - var(${totalHeaderHeightCssVar}));

    ${mobileMediaQuery} {
      align-items: start;
    }
  }
`;

export const streamReplayInnerWrapperCss = css`
  width: 90%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 16px;
    line-height: 26px;

    /* Ensure blurb renders above the animation */
    position: relative;
    z-index: 1;
  }

  .${videoPreviewClass} {
    height: 50.625vmin;
    width: 90vmin;
    /* Ensure video thumbnail renders above the animation */
    z-index: 1;
  }

  .${learnMoreClass} {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  .blurb {
    margin-top: 20px;
  }

  ${nonMobileMediaQuery} {
    flex-direction: row;

    .${blurbClass} {
      display: none;
    }

    /* Change flex order here so that animation appears above the video
    thumbnail on mobile */
    .${blurbWithAnimationClass} {
      order: 1;
      padding-left: 60px;
    }

    .${videoPreviewClass}, .${blurbWithAnimationClass} {
      flex-basis: 50%;
      flex-grow: 0;
    }

    .${learnMoreClass} {
      display: none;
    }

    .${videoPreviewClass} {
      height: unset;
      margin-top: 0;
    }
  }

  ${mobileMediaQuery} {
    margin-bottom: var(${totalHeaderHeightCssVar});

    .${blurbClass}, .${blurbWithAnimationClass} {
      display: none;
    }
  }

  /* Overrides for isolated layout */
  &.${isolatedStreamReplayClass} {
    .${learnMoreClass} {
      display: none;
    }

    ${mobileMediaQuery} {
      margin-top: 40px;

      .${videoPreviewClass} {
        width: 90vw;
        height: 50.625vw;
      }

      .${blurbWithAnimationClass} {
        /* On mobile, animation should show above the video thumbnail */
        order: -1;
        width: 100%;

        /* Hide text part of this content on mobile. The text will instead be displayed
        inside the stand alone .blurb element just below the thumbnail */
        p {
          display: none;
        }
      }

      .${blurbClass}, .${blurbWithAnimationClass} {
        display: block;
      }
    }
  }
`;

export const animationCss = css`
  /** Animation is roughly a square, so we can manipulate the width
  to directly control how much of the height of the container it takes up */
  width: 73%;
  /* SpsAnimation defines its own min height, but for post event use case
   we wan to support an even smaller size */
  min-height: 150px;

  ${mobileMediaQuery} {
    margin: 10px;
  }
`;

export const highlightsSectionCss = css`
  margin: 0 auto;
  padding-bottom: ${Spacing['4XL']}px;
  padding-left: ${Spacing.LG}px;
  padding-right: ${Spacing.LG}px;

  ${nonMobileMediaQuery} {
    max-width: 1130px;
    width: 90%;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const highlightsTitleCss = css`
  text-align: center;
  margin: 1em 0;
`;
