import { cx } from '@emotion/css';
import { Button, ButtonType, FormattedMessage } from '@snapchat/snap-design-system-marketing';
import type { Dispatch, FC, SetStateAction } from 'react';

import { Feature, useFeatureFlags } from '../../../../components/FeatureFlags';
import { bitmojiReactionToggleDarkModeCss } from './BitmojiControls.styles';

interface BitmojiReactionToggle {
  hideBitmoji: boolean;
  avatarId: string;
  setHideBitmoji: Dispatch<SetStateAction<boolean>>;
}

export const BitmoReactionToggle: FC<BitmojiReactionToggle> = ({
  hideBitmoji,
  setHideBitmoji,
  avatarId,
}) => {
  const features = useFeatureFlags();
  const enableDarkModeForLive = features[Feature.EXPERIENCE_ENABLE_ASL_STREAM] === 'true';

  const hideBitmojiClickHandler = () => {
    setHideBitmoji(!hideBitmoji);
  };

  const getBitmojiToggleText = () => {
    if (hideBitmoji && !avatarId) {
      return <FormattedMessage id="showReactions" defaultMessage="Show Reactions" />;
    } else if (!hideBitmoji && !avatarId) {
      return <FormattedMessage id="hideReactions" defaultMessage="Hide Reactions" />;
    } else if (hideBitmoji) {
      return <FormattedMessage id="show" defaultMessage="Show" />;
    }

    return <FormattedMessage id="hide" defaultMessage="Hide" />;
  };

  return (
    <Button
      type={ButtonType.Flat}
      iconName={hideBitmoji ? 'show' : 'hide'}
      onClick={hideBitmojiClickHandler}
      className={cx({ [bitmojiReactionToggleDarkModeCss]: enableDarkModeForLive })}
    >
      {getBitmojiToggleText()}
    </Button>
  );
};
